import {
  HomeOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  CalendarOutlined,
  PaperClipOutlined,
  FileOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { Menu, Layout, Space, Avatar, Popconfirm } from "antd";
import { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { stringToColour } from "../modules/utils";
import { Context } from "../utils/Context";
import { logoutUser } from "../modules/users";
const { Sider } = Layout;

const MySider = () => {
  const [collapsed, setCollapsed] = useState(false);
  const params = useLocation();
  const context = useContext(Context);
  const { loggedUser, getContextUser } = context;
  let activeMenu;
  if (
    params.pathname.includes("/feuillesderoute") ||
    params.pathname.includes("/feuillederoute")
  ) {
    activeMenu = "feuillesderoute";
  } else if (params.pathname.includes("/utilisateurs")) {
    activeMenu = "users";
  } else if (params.pathname.includes("/rapports")) {
    activeMenu = "rapports";
  } else if (params.pathname.includes("/heures")) {
    activeMenu = "heures";
  } else if (params.pathname.includes("/calendrier")) {
    activeMenu = "calendrier";
  }
  // console.log({ activeMenu });
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
      }}>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}>
        <div>
          <div className="logo">Routine</div>
          <Menu theme="dark" selectedKeys={[activeMenu]} mode="inline">
            <Menu.Item key="calendrier" icon={<CalendarOutlined />}>
              <Link to="/">Calendrier</Link>
            </Menu.Item>
            <Menu.Item key="feuillesderoute" icon={<PaperClipOutlined />}>
              <Link to="/feuillesderoute">Feuilles de route</Link>
            </Menu.Item>
            <Menu.Item key="rapports" icon={<FileOutlined />}>
              <Link to="/rapports">Rapports</Link>
            </Menu.Item>
            <Menu.Item key="heures" icon={<ClockCircleOutlined />}>
              <Link to="/heures">Heures</Link>
            </Menu.Item>
            <Menu.Item key="users" icon={<UserOutlined />}>
              <Link to="/utilisateurs">Utilisateurs</Link>
            </Menu.Item>
          </Menu>
        </div>

        <div
          style={{
            backgroundColor: "",
            height: 75,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            paddingLeft: 10,
            paddingRight: 10,
          }}>
          {loggedUser ? (
            <Popconfirm
              title="Voulez-vous vous déconnecter?"
              onConfirm={() => {
                logoutUser();
                setTimeout(() => {
                  getContextUser();
                }, 1000);
              }}
              okText="Oui"
              cancelText="Non">
              <Space style={{ cursor: "pointer" }}>
                <Avatar
                  size="large"
                  style={{
                    backgroundColor: stringToColour(loggedUser.username),
                  }}>
                  {loggedUser.lastname[0].toUpperCase()}
                  {loggedUser.firstname &&
                    loggedUser.firstname[0].toUpperCase()}
                </Avatar>
                {!collapsed && (
                  <span>
                    {loggedUser.lastname}{" "}
                    {loggedUser.firstname && loggedUser.firstname}
                  </span>
                )}
              </Space>
            </Popconfirm>
          ) : (
            <a
              style={{
                color: "white",
              }}
              href={"/login"}>
              <span style={{ textDecoration: "underline" }}>Se connecter</span>
            </a>
          )}
        </div>
      </div>
    </Sider>
  );
};

export default MySider;
