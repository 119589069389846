import { useEffect, useState, useContext, useRef } from "react";
import { Context } from "../utils/Context";
import dayjs from "dayjs";
import { getdbfieldsheures, getheures } from "../modules/api";
import { generateFeuillesDeTemps } from "../modules/pdf";
import {
  Table as AntTable,
  Space,
  Input,
  Button,
  Tooltip,
  Tag,
  Card,
  Select,
  DatePicker,
  Form,
  Typography,
  notification,
} from "antd";
import { FaFile, FaSearch, FaCheck, FaHammer } from "react-icons/fa";
import styled from "styled-components";
import { PrinterOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const columns = [
  {
    title: "Date",
    dataIndex: "feuilleDeRoute",
    render: (el) => dayjs(el.date).format("YYYY-MM-DD"),
  },
  {
    title: "Employé",
    dataIndex: "nom",
  },
  {
    title: "Contrat",
    dataIndex: "feuilleDeRoute",
    render: (el) => el.noprojet,
  },
  {
    title: "Heures",
    render: (text, record) => (
      <div>
        {record.absent ? (
          <div style={{ fontWeight: "bold" }}>Absent</div>
        ) : (
          <>
            <div>
              Début: <span style={{ fontWeight: "bold" }}>{record.debut}</span>
            </div>
            <div>
              Fin: <span style={{ fontWeight: "bold" }}>{record.fin}</span>
            </div>
            <div>
              Repas: <span style={{ fontWeight: "bold" }}>{record.repas}</span>
            </div>
            <div>
              Total: <span style={{ fontWeight: "bold" }}>{record.total}</span>
            </div>
            {record.perdiem.length ? (
              <div>
                Perdiem:{" "}
                <span style={{ fontWeight: "bold" }}>{record.perdiem}</span>
              </div>
            ) : null}
          </>
        )}
      </div>
    ),
  },
];

const TopTabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Table = ({
  feuillesDeRouteMainDOeuvre,
  searchLoading,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  return (
    <AntTable
      bordered
      columns={columns}
      rowKey={(record) => record._id}
      dataSource={feuillesDeRouteMainDOeuvre}
      pagination={false}
      loading={searchLoading}
      rowSelection={{
        type: "checkbox",
        onChange: (sRowKeys, sRows) => {
          setSelectedRowKeys(sRowKeys);
        },
        selectedRowKeys,
      }}
    />
  );
};

const HeuresPage = () => {
  const context = useContext(Context);
  const { setHeaderType } = context;
  const [fields, setFields] = useState({
    employes: [],
    noprojets: [],
  });
  const [searchLoading, setSearchLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [feuillesDeRouteMainDOeuvre, setFeuillesDeRouteMainDOeuvre] = useState(
    []
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  useEffect(() => {
    setHeaderType("none");
    getdbfieldsheures().then((result) => {
      setFields(result);
    });
  }, []);
  const onFinish = (values) => {
    if (!values.daterange) {
      notification.error({
        message: "Veuillez sélectionner une date",
        placement: "top",
      });
      return;
    }
    // console.log("Success:", values);
    setSearchLoading(true);
    getheures(values).then((result) => {
      // console.log({ result });
      const newFeuillesDeRouteMainDOeuvre = [
        ...result.feuillesDeRouteMainDOeuvre,
      ]
        .map((f) => ({
          ...f,
          feuilleDeRoute: result.feuillesDeRoute.find(
            (t) => t.id === f.idfeuillederoute
          ),
        }))
        .sort((a, b) => a.nom.localeCompare(b.nom));
      setFeuillesDeRouteMainDOeuvre(newFeuillesDeRouteMainDOeuvre);
      setSelectedRowKeys(newFeuillesDeRouteMainDOeuvre.map((f) => f._id));
      setSearchLoading(false);
    });
  };
  const I18nStatut = (status) => {
    switch (status) {
      case "todo":
        return "À faire";
      case "complete":
        return "À Approuver";
      case "approved":
        return "Approuvée";
    }
  };
  return (
    <div className="HeuresPage">
      <div className="HeuresPageContainer">
        <Card style={{ marginBottom: 15 }}>
          <Form
            style={{ display: "flex", flexWrap: "wrap", gap: 5 }}
            onFinish={onFinish}
          >
            <Form.Item noStyle name="daterange">
              <RangePicker onChange={() => setFeuillesDeRouteMainDOeuvre([])} />
            </Form.Item>
            <Form.Item noStyle name="employes">
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "200px",
                }}
                onChange={() => setFeuillesDeRouteMainDOeuvre([])}
                dropdownMatchSelectWidth={false}
                placeholder="Employés"
                options={fields.employes.map((e) => {
                  return { label: e, value: e };
                })}
              />
            </Form.Item>
            <Form.Item noStyle name="noprojets">
              <Select
                mode="tags"
                style={{
                  width: "200px",
                }}
                onChange={() => setFeuillesDeRouteMainDOeuvre([])}
                placeholder="N° de projets"
                options={fields.noprojets.map((e) => {
                  return { label: e, value: e };
                })}
              />
            </Form.Item>
            <Button htmlType="submit" type="primary">
              Rechercher
            </Button>
          </Form>
        </Card>
        <TopTabContainer>
          <Space>
            <Text>Nombre de feuilles de temps sélectionnées:</Text>
            <Text strong>
              {selectedRowKeys.length} / {feuillesDeRouteMainDOeuvre.length}
            </Text>
          </Space>
          <Space>
            <Button
              type="danger"
              icon={<PrinterOutlined />}
              loading={printLoading}
              disabled={!feuillesDeRouteMainDOeuvre.length}
              onClick={async () => {
                generateFeuillesDeTemps(
                  feuillesDeRouteMainDOeuvre.filter((f) =>
                    selectedRowKeys.includes(f._id)
                  )
                );
              }}
            >
              Imprimer
            </Button>
          </Space>
        </TopTabContainer>
        <Table
          feuillesDeRouteMainDOeuvre={feuillesDeRouteMainDOeuvre}
          searchLoading={searchLoading}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      </div>
    </div>
  );
};

export default HeuresPage;
