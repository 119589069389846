import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const getToken = () => {
  const token = cookies.get("token") || "";
  return token;
};

export const getComptabilite = async (idfeuillederoute) => {
  const token = getToken();
  try {
    const response = await axios.get(
      `/db/getcomptabilite?idfeuillederoute=${idfeuillederoute}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error fetching comptabilite:", err);
    if (err.response?.status === 401) {
      window.location.href = "/login";
    }
    return { success: false, error: err.message };
  }
};

export const updateComptabilite = async ({
  idfeuillederoute,
  factureinterne,
  bondelivraison,
  feuilledetemps,
  facturevente,
}) => {
  const token = getToken();
  try {
    const response = await axios.post(
      "/db/savecomptabilite",
      {
        idfeuillederoute,
        factureinterne,
        bondelivraison,
        feuilledetemps,
        facturevente,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error updating comptabilite:", err);
    if (err.response?.status === 401) {
      window.location.href = "/login";
    }
    return { success: false, error: err.message };
  }
};
