import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { FontAwesomeBase64 } from "./customfont";
import TraLogo from "../assets/logo.jpg";
import { Buffer } from "buffer";
import * as FileSaver from "file-saver";
import JSZip from "jszip";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs["FontAwesome.ttf"] = FontAwesomeBase64;
pdfMake.fonts = {
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
  FontAwesome: {
    normal: "FontAwesome.ttf",
    bold: "FontAwesome.ttf",
    italics: "FontAwesome.ttf",
    bolditalics: "FontAwesome.ttf",
  },
};
dayjs.extend(customParseFormat);
dayjs.locale("fr");

const generateMultiplePDF = async (feuillesDeRoute) => {
  // console.log({ feuillesDeRoute });
  const zip = new JSZip();
  const pdfs = zip.folder("pdfs");
  await Promise.all(
    feuillesDeRoute.map(async (feuille, index) => {
      const dd = generateContentFeuilleDeRoutePDF(
        feuille.feuilleDeRoute,
        feuille.feuilleDeRouteQuantites,
        feuille.feuilleDeRoutePeinture,
        feuille.feuilleDeRouteMainDoeuvre,
        feuille.feuilleDeRouteVehicule,
        feuille.noDonneur
      );
      await new Promise((resolve) => {
        pdfMake.createPdf(dd).getBlob(async (blob) => {
          pdfs.file(
            `${index}-${feuille.feuilleDeRoute.noprojet}-${feuille.feuilleDeRoute.date}.pdf`,
            blob,
            { binary: true }
          );
          resolve();
        });
      });
    })
  );

  await pdfs.generateAsync({ type: "blob" }).then((content) => {
    FileSaver.saveAs(content, `${dayjs().unix()}.zip`);
  });
};

const generateFeuilleDeRoutePDF = (
  feuilleDeRoute,
  feuilleDeRouteQuantites,
  feuilleDeRoutePeinture,
  feuilleDeRouteMainDoeuvres,
  feuilleDeRouteVehicules,
  feuilleDeRouteCommentaires,
  noDonneur
) => {
  const dd = generateContentFeuilleDeRoutePDF(
    feuilleDeRoute,
    feuilleDeRouteQuantites,
    feuilleDeRoutePeinture,
    feuilleDeRouteMainDoeuvres,
    feuilleDeRouteVehicules,
    feuilleDeRouteCommentaires,
    noDonneur
  );
  pdfMake.createPdf(dd).print();
};

const generateContentFeuilleDeRoutePDF = (
  feuilleDeRoute,
  feuilleDeRouteQuantites,
  feuilleDeRoutePeinture,
  feuilleDeRouteMainDoeuvres,
  feuilleDeRouteVehicules,
  feuilleDeRouteCommentaires,
  feuilleDeRouteComptabilite,
  noDonneur
) => {
  let content = [];
  // console.log({
  //   feuilleDeRoute,
  //   feuilleDeRouteQuantites,
  //   feuilleDeRoutePeinture,
  //   feuilleDeRouteMainDoeuvres,
  //   feuilleDeRouteVehicules,
  //   noDonneur,
  // });
  content.push(resume(feuilleDeRoute, noDonneur));
  content.push(quantities(feuilleDeRouteQuantites));
  content.push(travauxtermines(feuilleDeRoute));
  content.push(peinture(feuilleDeRoutePeinture));
  content.push(
    maindoeuvre(feuilleDeRouteMainDoeuvres, feuilleDeRouteVehicules)
  );
  content.push(vehicules(feuilleDeRouteVehicules));
  content.push(commentaires(feuilleDeRoute));
  if (feuilleDeRouteCommentaires.length > 0)
    content.push(commentairessysteme(feuilleDeRouteCommentaires));
  content.push(comptabilite(feuilleDeRoute, feuilleDeRouteComptabilite));
  let dd = {
    header: function (currentPage, pageCount) {
      return {
        layout: "noBorders",
        margin: [40, 20],
        table: {
          dontBreakRows: true,
          widths: ["auto", "*", "auto"],
          body: [
            [
              {
                image: TraLogo,
                width: 150,
              },
              {
                text: "FEUILLE DE ROUTE",
                bold: true,
                fontSize: 26,
                margin: [10, 7],
              },
              {
                margin: [0, 10],
                stack: [
                  {
                    text: "N°OP/GP/DT-002",
                    bold: true,
                  },
                  {
                    text: "Rev.4",
                  },
                  {
                    text: "Page " + currentPage.toString() + " de " + pageCount,
                  },
                  {
                    text: "Usage: externe",
                    bold: true,
                  },
                ],
              },
            ],
          ],
        },
      };
    },
    pageSize: "LEGAL",
    pageOrientation: "portrait",
    pageMargins: [40, 110, 40, 40],
    content,
    defaultStyle: {
      font: "Roboto",
    },
    styles: {},
  };
  dd.styles.symbol = { font: "FontAwesome" };
  return dd;
};
const resume = (feuilleDeRoute, noDonneur) => {
  // console.log({ feuilleDeRoute });
  return {
    stack: [
      // { text: 'Informations', bold: true, fontSize: 16 },
      {
        margin: [0, 0, 0, 20],
        table: {
          dontBreakRows: true,
          widths: ["auto", "*", "auto", "auto"],
          body: [
            [
              { text: "Nom Client:", bold: true },
              { text: feuilleDeRoute.client },
              { text: "Date:", bold: true },
              {
                text: dayjs(feuilleDeRoute.date).format("D MMMM YYYY"),
              },
            ],
            [
              { text: "N° Dossier TRA:", bold: true },
              { text: feuilleDeRoute.noprojet },
              { text: "MTQ / NO:", bold: true },
              { text: noDonneur || "" },
            ],
            [
              { text: "Chargé de projet TRA:", bold: true },
              { text: feuilleDeRoute.chargedeprojet },
              { text: "Hre d'arrivée sur chantier:", bold: true },
              { text: feuilleDeRoute.heurearriveechantier },
            ],
            [
              { text: "Projet:", bold: true },
              { text: feuilleDeRoute.descriptionprojet, colSpan: 3 },
              {},
              {},
            ],
            [
              { text: "Température:", bold: true },
              { text: feuilleDeRoute.temperature || "" },
              { text: "Météo:", bold: true },
              { text: feuilleDeRoute.meteo || "" },
            ],
            [
              { text: "Humidité chaussée:", bold: true },
              { text: feuilleDeRoute.chausseehumiditee || "" },
              { text: "Propreté chaussée:", bold: true },
              { text: feuilleDeRoute.chausseeproprete || "" },
            ],
          ],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return columnIndex % 2 === 0 ? "#CCCCCC" : null;
          },
        },
      },
    ],
  };
};

const quantities = (feuilleDeRouteQuantites) => {
  let table = [];
  Object.keys(feuilleDeRouteQuantites).map((categorie) => {
    const articles = feuilleDeRouteQuantites[categorie];
    table.push([
      {
        text: I18n(categorie).name,
        bold: true,
        colSpan: I18n(categorie).couleur ? 2 : 8,
        fillColor: "#eeeeee",
      },
    ]);
    if (I18n(categorie).couleur) {
      table[table.length - 1].push({});
      table[table.length - 1].push(
        ...[
          {
            text: "Jaune",
            bold: true,
            fillColor: "#eeeeee",
          },
          {
            text: "Blanc",
            bold: true,
            fillColor: "#eeeeee",
          },
          {
            text: "Skip",
            bold: true,
            fillColor: "#eeeeee",
          },
          {
            text: "Jaune",
            bold: true,
            fillColor: "#eeeeee",
          },
          {
            text: "Blanc",
            bold: true,
            fillColor: "#eeeeee",
          },
          {
            text: "Skip",
            bold: true,
            fillColor: "#eeeeee",
          },
        ]
      );
    }
    articles.map((article) => {
      table.push([
        { text: article.description },
        { text: article.unite, alignment: "center" },
        I18n(categorie).couleur
          ? { text: article.jauneest || 0, alignment: "center" }
          : { text: article.quantiteest || 0, colSpan: 3, alignment: "center" },
        I18n(categorie).couleur
          ? { text: article.blancest || 0, alignment: "center" }
          : {},
        I18n(categorie).couleur
          ? { text: article.skipest || 0, alignment: "center" }
          : {},
        I18n(categorie).couleur
          ? {
              text: article.jaunereel || 0,
              alignment: "center",
            }
          : {
              text: article.quantitereel || 0,
              colSpan: 3,
              alignment: "center",
            },
        I18n(categorie).couleur
          ? {
              text: article.blancreel || 0,
              alignment: "center",
            }
          : {},
        I18n(categorie).couleur
          ? {
              text: article.skipreel || 0,
              alignment: "center",
            }
          : {},
      ]);
    });
  });
  return {
    stack: [
      { text: "Quantités", bold: true, fontSize: 16 },
      {
        margin: [0, 0, 0, 0],
        table: {
          dontBreakRows: true,
          widths: ["*", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
          body: [
            [
              { text: "Description", bold: true, fillColor: "#CCCCCC" },
              {
                text: "Unité",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
              {
                text: "Quantités estimées",
                bold: true,
                fillColor: "#CCCCCC",
                colSpan: 3,
                alignment: "center",
              },
              {},
              {},
              {
                text: "Quantités réalisées",
                bold: true,
                fillColor: "#CCCCCC",
                colSpan: 3,
                alignment: "center",
              },
              {},
              {},
            ],
            ...table,
          ],
        },
      },
    ],
  };
};

const travauxtermines = (feuilleDeRoute) => {
  // console.log({ feuilleDeRoute });
  return [
    {
      text: [
        { text: "Travaux terminés : ", fontSize: 14 },
        {
          text: `${
            feuilleDeRoute.travauxcompletes
              ? feuilleDeRoute.travauxcompletes.toUpperCase()
              : ""
          }`,
          bold: true,
          fontSize: 14,
        },
        {
          text: ". Heure d'arrivée : ",
          fontSize: 14,
        },
        {
          text: feuilleDeRoute.heurereellearriveechantier,
          fontSize: 14,
          bold: true,
        },
        {
          text: ". Heure de départ : ",
          fontSize: 14,
        },
        {
          text: feuilleDeRoute.heurereelledepartchantier,
          fontSize: 14,
          bold: true,
        },
      ],
      margin: [0, 0, 0, 20],
    },
  ];
};

const peinture = (feuilleDeRoutePeinture) => {
  // console.log({
  //   feuilleDeRouteQuantites,
  //   feuilleDeRoutePeinture,
  //   quantites,
  //   peinture,
  // });
  if (!feuilleDeRoutePeinture) return;
  let tablecourteduree = [];
  if (parseFloat(feuilleDeRoutePeinture.cd_chaudiere_jaune_total) > 0) {
    tablecourteduree.push([
      {
        text:
          "Jaune (chaudière) Départ : " +
          feuilleDeRoutePeinture.cd_chaudiere_jaune_start +
          "u" +
          " Arrivée : " +
          feuilleDeRoutePeinture.cd_chaudiere_jaune_finish +
          "u",
      },
      {
        text: feuilleDeRoutePeinture.cd_chaudiere_jaune_total + " litres",
        alignment: "center",
      },
    ]);
  }
  if (parseFloat(feuilleDeRoutePeinture.cd_chaudiere_blanc_total) > 0) {
    tablecourteduree.push([
      {
        text:
          "Blanc (chaudière) Départ : " +
          feuilleDeRoutePeinture.cd_chaudiere_blanc_start +
          "u" +
          " Arrivée : " +
          feuilleDeRoutePeinture.cd_chaudiere_blanc_finish +
          "u",
      },
      {
        text: feuilleDeRoutePeinture.cd_chaudiere_blanc_total + " litres",
        alignment: "center",
      },
    ]);
  }
  feuilleDeRoutePeinture.cd_chaudiere_couleurspeciale?.map((chaudiere) => {
    tablecourteduree.push([
      {
        text:
          capitalizeFirstLetter(chaudiere.couleur) +
          " (chaudière) Départ : " +
          chaudiere.start +
          "u" +
          " Arrivée : " +
          chaudiere.finish +
          "u",
      },
      {
        text: chaudiere.total + " litres",
        alignment: "center",
      },
    ]);
  });
  if (parseFloat(feuilleDeRoutePeinture.cd_sac_bille_total) > 0) {
    tablecourteduree.push([
      {
        text:
          "Bille (sac) Départ : " +
          feuilleDeRoutePeinture.cd_sac_bille_start +
          "u" +
          " Arrivée : " +
          feuilleDeRoutePeinture.cd_sac_bille_finish +
          "u",
      },
      {
        text: feuilleDeRoutePeinture.cd_sac_bille_total + " kg",
        alignment: "center",
      },
    ]);
  }
  let barrils_jaune = [];
  feuilleDeRoutePeinture.cd_barril_jaune?.map((barril, index) => {
    barrils_jaune.push(
      "#" +
        (index + 1) +
        " Départ : " +
        barril.start +
        '" Arrivée : ' +
        barril.finish +
        '"'
    );
  });
  if (barrils_jaune.length) {
    tablecourteduree.push([
      { text: "Jaune (barril)\n" + barrils_jaune.join("\n") },
      {
        text:
          (feuilleDeRoutePeinture.cd_barril_jaune.reduce(
            (prev, cur) => prev + cur.total,
            0
          ) || 0) + " litres",
        alignment: "center",
      },
    ]);
  }
  let barrils_blanc = [];
  feuilleDeRoutePeinture.cd_barril_blanc?.map((barril, index) => {
    barrils_blanc.push(
      "#" +
        (index + 1) +
        " Départ : " +
        barril.start +
        '" Arrivée : ' +
        barril.finish +
        '"'
    );
  });
  if (barrils_blanc.length) {
    tablecourteduree.push([
      { text: "Blanc (barril)\n" + barrils_blanc.join("\n") },
      {
        text:
          (feuilleDeRoutePeinture.cd_barril_blanc.reduce(
            (prev, cur) => prev + cur.total,
            0
          ) || 0) + " litres",
        alignment: "center",
      },
    ]);
  }
  let barrils_bille = [];
  feuilleDeRoutePeinture.cd_barril_bille?.map((barril, index) => {
    barrils_bille.push(
      "#" +
        (index + 1) +
        " Départ : " +
        barril.start +
        '" Arrivée : ' +
        barril.finish +
        '"'
    );
  });
  if (barrils_bille.length) {
    tablecourteduree.push([
      { text: "Bille (barril)\n" + barrils_bille.join("\n") },
      {
        text:
          (feuilleDeRoutePeinture.cd_barril_bille.reduce(
            (prev, cur) => prev + cur.total,
            0
          ) || 0) + " kg",
        alignment: "center",
      },
    ]);
  }
  let reservoirs_jaune = [];
  feuilleDeRoutePeinture.cd_reservoir_jaune?.map((reservoir, index) => {
    reservoirs_jaune.push(
      "#" +
        (index + 1) +
        " Départ : " +
        reservoir.start +
        '" Arrivée : ' +
        reservoir.finish +
        '"'
    );
  });
  if (reservoirs_jaune.length) {
    tablecourteduree.push([
      { text: "Jaune (reservoir)\n" + reservoirs_jaune.join("\n") },
      {
        text:
          (feuilleDeRoutePeinture.cd_reservoir_jaune.reduce(
            (prev, cur) => prev + cur.total,
            0
          ) || 0) + " litres",
        alignment: "center",
      },
    ]);
  }
  let reservoirs_blanc = [];
  feuilleDeRoutePeinture.cd_reservoir_blanc?.map((reservoir, index) => {
    reservoirs_blanc.push(
      "#" +
        (index + 1) +
        " Départ : " +
        reservoir.start +
        '" Arrivée : ' +
        reservoir.finish +
        '"'
    );
  });
  if (reservoirs_blanc.length) {
    tablecourteduree.push([
      { text: "Blanc (réservoir)\n" + reservoirs_blanc.join("\n") },
      {
        text:
          (feuilleDeRoutePeinture.cd_reservoir_blanc.reduce(
            (prev, cur) => prev + cur.total,
            0
          ) || 0) + " litres",
        alignment: "center",
      },
    ]);
  }
  let reservoirs_bille = [];
  feuilleDeRoutePeinture.cd_reservoir_bille?.map((reservoir, index) => {
    reservoirs_bille.push(
      "#" +
        (index + 1) +
        " Départ : " +
        reservoir.start +
        '" Arrivée : ' +
        reservoir.finish +
        '"'
    );
  });
  if (reservoirs_bille.length) {
    tablecourteduree.push([
      { text: "Bille (réservoir)\n" + reservoirs_bille.join("\n") },
      {
        text:
          (feuilleDeRoutePeinture.cd_reservoir_bille.reduce(
            (prev, cur) => prev + cur.total,
            0
          ) || 0) + " kg",
        alignment: "center",
      },
    ]);
  }
  if (tablecourteduree.length) {
    tablecourteduree.unshift([
      {
        text: "Marquage Courte Durée",
        fillColor: "#eeeeee",
        colSpan: 2,
        bold: true,
      },
      {},
    ]);
  }
  let tablemoyenneduree = [];
  if (feuilleDeRoutePeinture.md_reservoir_jaune_total) {
    tablemoyenneduree.push([
      {
        text:
          "Jaune Lot:" + (feuilleDeRoutePeinture.md_reservoir_jaune_lot || ""),
      },
      {
        text:
          (feuilleDeRoutePeinture.md_reservoir_jaune_total || 0) + " litres",
        alignment: "center",
      },
    ]);
  }
  if (feuilleDeRoutePeinture.md_reservoir_blanc_total) {
    tablemoyenneduree.push([
      {
        text:
          "Blanc Lot:" + (feuilleDeRoutePeinture.md_reservoir_blanc_lot || ""),
      },
      {
        text:
          (feuilleDeRoutePeinture.md_reservoir_blanc_total || 0) + " litres",
        alignment: "center",
      },
    ]);
  }
  feuilleDeRoutePeinture.md_reservoir_couleurspeciale?.map(
    (couleurspeciale) => {
      tablemoyenneduree.push([
        {
          text:
            capitalizeFirstLetter(couleurspeciale.couleur) +
            " Lot:" +
            couleurspeciale.lot,
        },
        {
          text: couleurspeciale.total + " litres",
          alignment: "center",
        },
      ]);
    }
  );
  if (feuilleDeRoutePeinture.md_reservoir_durcisseur_total) {
    tablemoyenneduree.push([
      {
        text:
          "Durcisseur Lot:" +
          (feuilleDeRoutePeinture.md_reservoir_durcisseur_lot || ""),
      },
      {
        text:
          (feuilleDeRoutePeinture.md_reservoir_durcisseur_total || 0) +
          " litres",
        alignment: "center",
      },
    ]);
  }

  let md_reservoirs_bille = [];
  feuilleDeRoutePeinture.md_reservoir_bille?.map((reservoir, index) => {
    md_reservoirs_bille.push(
      "#" +
        (index + 1) +
        " Départ Gauche : " +
        reservoir.depart_gauche +
        '" Arrivée Gauche : ' +
        reservoir.arrivee_gauche +
        '" Total Gauche : ' +
        reservoir.total_gauche +
        "kg"
    );
    md_reservoirs_bille.push(
      "#" +
        (index + 1) +
        " Départ Droite : " +
        reservoir.depart_droite +
        '" Arrivée Droite : ' +
        reservoir.arrivee_droite +
        '" Total Droite : ' +
        reservoir.total_droite +
        "kg"
    );
  });
  if (md_reservoirs_bille.length) {
    tablemoyenneduree.push([
      { text: "Bille (réservoir)\n" + md_reservoirs_bille.join("\n") },
      {
        text:
          (feuilleDeRoutePeinture.md_reservoir_bille.reduce(
            (prev, cur) => prev + cur.total_gauche + cur.total_droite,
            0
          ) || 0) + " kg",
        alignment: "center",
      },
    ]);
  }
  if (tablemoyenneduree.length) {
    tablemoyenneduree.unshift([
      {
        text: "Marquage Moyenne Durée",
        fillColor: "#eeeeee",
        colSpan: 2,
        bold: true,
      },
      {},
    ]);
  }
  let tablemma = [];
  feuilleDeRoutePeinture.mma?.map((couleur) => {
    tablemma.push(
      [
        {
          text:
            capitalizeFirstLetter(couleur.couleur) +
            " Composant A (chaudière) Départ : " +
            couleur.composant_A_start +
            "u" +
            " Arrivée : " +
            couleur.composant_A_finish +
            "u",
        },
        {
          text: couleur.composant_A_total + " litres",
          alignment: "center",
        },
      ],
      [
        {
          text:
            capitalizeFirstLetter(couleur.couleur) +
            " Composant B (chaudière) Départ : " +
            couleur.composant_B_start +
            "u" +
            " Arrivée : " +
            couleur.composant_B_finish +
            "u",
        },
        {
          text: couleur.composant_B_total + " litres",
          alignment: "center",
        },
      ]
    );
  });
  if (tablemma.length) {
    tablemma.unshift([
      {
        text: "MMA",
        fillColor: "#eeeeee",
        colSpan: 2,
        bold: true,
      },
      {},
    ]);
  }
  const tablegabarits = [
    {
      text: "Gabarits",
    },
    {
      text:
        (feuilleDeRoutePeinture.gabarits ?? "0") +
        ` unité${feuilleDeRoutePeinture.gabarits > 1 ? "s" : ""}`,
      alignment: "center",
    },
  ];
  if (
    !tablecourteduree.length &&
    !tablemoyenneduree.length &&
    !tablemma.length &&
    (!feuilleDeRoutePeinture.gabarits ||
      feuilleDeRoutePeinture.gabarits === "0")
  )
    return;
  return {
    stack: [
      { text: "Peinture", bold: true, fontSize: 18 },
      {
        margin: [0, 0, 0, 20],
        table: {
          dontBreakRows: true,
          widths: ["*", "auto"],
          body: [
            [
              { text: "Description", bold: true, fillColor: "#CCCCCC" },
              {
                text: "Total",
                bold: true,
                fillColor: "#CCCCCC",
              },
            ],
            tablegabarits,
            ...tablecourteduree,
            ...tablemoyenneduree,
            ...tablemma,
          ],
        },
      },
    ],
  };
};

const maindoeuvre = (feuilleDeRouteMainDoeuvre, feuilleDeRouteVehicule) => {
  // console.log({ feuilleDeRouteMainDoeuvre, feuilleDeRouteVehicule });
  if (!feuilleDeRouteMainDoeuvre) {
    feuilleDeRouteMainDoeuvre = {};
  }
  if (!feuilleDeRouteVehicule) {
    feuilleDeRouteVehicule = {};
  }
  const table = [];
  Object.values(feuilleDeRouteMainDoeuvre).map((employes) => {
    employes.map((employe) => {
      table.push([
        {
          text:
            feuilleDeRouteVehicule[employe.idcamion] &&
            feuilleDeRouteVehicule[employe.idcamion][0].nom,
          decoration: employe.absent && "lineThrough",
          fillColor: employe.absent ? "#CCCCCC" : "#FFFFFF",
        },
        {
          text: JSON.parse(
            feuilleDeRouteVehicule[employe.idcamion]
              ? feuilleDeRouteVehicule[employe.idcamion][0].remorques
              : "[]"
          ).join(" "),
          fillColor: employe.absent ? "#CCCCCC" : "#FFFFFF",
        },
        {
          text: employe.nom,
          decoration: employe.absent && "lineThrough",
          fillColor: employe.absent ? "#CCCCCC" : "#FFFFFF",
        },
        {
          text: employe.perdiem,
          alignment: "center",
          fillColor: employe.absent ? "#CCCCCC" : "#FFFFFF",
        },
        {
          text: employe.chauffeur ? "X" : "",
          alignment: "center",
          fillColor: employe.absent ? "#CCCCCC" : "#FFFFFF",
        },
        {
          text: employe.contremaitre ? "X" : "",
          alignment: "center",
          fillColor: employe.absent ? "#CCCCCC" : "#FFFFFF",
        },
        {
          text: employe.debut,
          alignment: "center",
          fillColor: employe.absent ? "#CCCCCC" : "#FFFFFF",
        },
        {
          text: employe.fin,
          alignment: "center",
          fillColor: employe.absent ? "#CCCCCC" : "#FFFFFF",
        },
        {
          text: employe.absent ? "" : employe.repas,
          alignment: "center",
          fillColor: employe.absent ? "#CCCCCC" : "#FFFFFF",
        },
        {
          text: employe.total,
          alignment: "center",
          fillColor: employe.absent ? "#CCCCCC" : "#FFFFFF",
        },
      ]);
    });
  });
  return {
    stack: [
      { text: "Employés", bold: true, fontSize: 18 },
      {
        margin: [0, 0, 0, 20],
        table: {
          dontBreakRows: true,
          widths: [
            "auto",
            "auto",
            "*",
            "auto",
            "auto",
            "auto",
            "auto",
            "auto",
            "auto",
            "auto",
          ],
          body: [
            [
              { text: "#Unité", bold: true, fillColor: "#CCCCCC", rowSpan: 2 },
              {
                text: "#Rem.",
                bold: true,
                fillColor: "#CCCCCC",
                rowSpan: 2,
              },
              {
                text: "Employé",
                bold: true,
                fillColor: "#CCCCCC",
                rowSpan: 2,
              },
              {
                text: "Per",
                bold: true,
                fillColor: "#CCCCCC",
                rowSpan: 2,
                alignment: "center",
              },
              {
                text: "Chauf",
                bold: true,
                fillColor: "#CCCCCC",
                rowSpan: 2,
                alignment: "center",
              },
              {
                text: "Chef",
                bold: true,
                fillColor: "#CCCCCC",
                rowSpan: 2,
                alignment: "center",
              },
              {
                text: "Heures",
                bold: true,
                fillColor: "#CCCCCC",
                colSpan: 3,
                alignment: "center",
              },
              {},
              {},
              {
                text: "Heures totales",
                bold: true,
                fillColor: "#CCCCCC",
                rowSpan: 2,
                alignment: "center",
              },
            ],
            [
              {},
              {},
              {},
              {},
              {},
              {},
              {
                text: "Début",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
              {
                text: "Fin",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
              {
                text: "Repas",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
              {},
            ],
            ...table,
          ],
        },
      },
    ],
  };
};
const vehicules = (feuilleDeRouteVehicule) => {
  // console.log({ feuilleDeRouteVehicule });
  if (!feuilleDeRouteVehicule) {
    feuilleDeRouteVehicule = {};
  }
  const table = [];
  Object.values(feuilleDeRouteVehicule).map(([camion]) => {
    table.push([
      { text: camion.nom },
      { text: camion.bonetat ? "X" : "", alignment: "center" },
      { text: camion.bonetat ? "" : "X", alignment: "center" },
      { text: camion.commentaireetat },
    ]);
  });
  return {
    stack: [
      { text: "Véhicules", bold: true, fontSize: 18 },
      {
        margin: [0, 0, 0, 20],
        table: {
          dontBreakRows: true,
          widths: ["auto", "auto", "auto", "*"],
          body: [
            [
              {
                text: "Véhicules",
                bold: true,
                fillColor: "#CCCCCC",
                rowSpan: 2,
              },
              {
                text: "État du véhicule / défectuosité",
                bold: true,
                fillColor: "#CCCCCC",
                colSpan: 2,
              },
              {},
              {
                text: "Commentaires",
                bold: true,
                fillColor: "#CCCCCC",
                rowSpan: 2,
              },
            ],
            [
              {},
              {
                text: "OUI",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
              {
                text: "NON",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
              {},
            ],
            ...table,
          ],
        },
      },
    ],
  };
};

const commentaires = (feuilleDeRoute) => {
  return {
    stack: [
      { text: "Commentaires Bureau", bold: true, fontSize: 18 },
      {
        margin: [0, 0, 0, 20],
        table: {
          dontBreakRows: true,
          widths: ["*"],
          body: [
            [
              {
                text: feuilleDeRoute.commentaires || " ",
              },
            ],
          ],
        },
      },
      { text: "Commentaires Chantier", bold: true, fontSize: 18 },
      {
        margin: [0, 0, 0, 20],
        table: {
          dontBreakRows: true,
          widths: ["*"],
          body: [
            [
              {
                text: feuilleDeRoute.commentaireschantier || " ",
              },
            ],
          ],
        },
      },
    ],
  };
};

const commentairessysteme = (feuilleDeRouteCommentaires) => {
  return {
    stack: [
      { text: "Commentaires Systeme", bold: true, fontSize: 18 },
      {
        margin: [0, 0, 0, 20],
        table: {
          dontBreakRows: true,
          widths: ["auto", "*", "auto"],
          body: [
            [
              { text: "Utilisateur", bold: true, fillColor: "#CCCCCC" },
              { text: "Commentaire", bold: true, fillColor: "#CCCCCC" },
              { text: "Date", bold: true, fillColor: "#CCCCCC" },
            ],
            ...(feuilleDeRouteCommentaires || []).map((comment) => [
              { text: comment.user },
              { text: comment.commentaire },
              { text: dayjs(comment.date).format("DD/MM/YYYY HH:mm") },
            ]),
          ],
        },
      },
    ],
  };
};

const comptabilite = (feuilleDeRoute, feuilleDeRouteComptabilite) => {
  return {
    stack: [
      { text: "Administration", bold: true, fontSize: 18 },
      {
        margin: [0, 0, 0, 20],
        table: {
          dontBreakRows: true,
          widths: ["auto", "auto", "auto", "auto", "auto"],
          body: [
            [
              {
                text: "Statut",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
              {
                text: "Feuille de temps",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
              {
                text: "Facture interne",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
              {
                text: "Bon de livraison",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
              {
                text: "Facture (vente)",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
            ],
            [
              {
                text: I18n(feuilleDeRoute.status).name,
                alignment: "center",
              },
              {
                text: feuilleDeRouteComptabilite.feuilledetemps,
                alignment: "center",
              },
              {
                text: feuilleDeRouteComptabilite.factureinterne,
                alignment: "center",
              },
              {
                text: feuilleDeRouteComptabilite.bondelivraison,
                alignment: "center",
              },
              {
                text: feuilleDeRouteComptabilite.facturevente,
                alignment: "center",
              },
            ],
          ],
        },
      },
    ],
  };
};

const generateFeuillesDeTemps = (feuillesDeTemps) => {
  let content = [];
  content.push(feuillesdetemps(feuillesDeTemps));
  let dd = {
    header: function (currentPage, pageCount) {
      return {
        layout: "noBorders",
        margin: [40, 20],
        table: {
          dontBreakRows: true,
          widths: ["auto", "*", "auto"],
          body: [
            [
              {
                image: TraLogo,
                width: 150,
              },
              {
                text: "FEUILLES DE TEMPS",
                bold: true,
                fontSize: 26,
                margin: [10, 7],
              },
              {
                margin: [0, 10],
                stack: [
                  {
                    text: "Page " + currentPage.toString() + " de " + pageCount,
                  },
                ],
              },
            ],
          ],
        },
      };
    },
    pageSize: "LETTER",
    pageOrientation: "portrait",
    pageMargins: [40, 110, 40, 40],
    content,
    defaultStyle: {
      font: "Roboto",
    },
    styles: {},
  };
  dd.styles.symbol = { font: "FontAwesome" };
  pdfMake.createPdf(dd).print();
};

const feuillesdetemps = (feuillesDeTemps) => {
  const table = [];
  feuillesDeTemps.map((feuille) => {
    table.push([
      {
        text: dayjs(feuille.feuilleDeRoute.date).format("YYYY-MM-DD"),
        fillColor: feuille.absent ? "#CCCCCC" : "#FFFFFF",
      },
      {
        text: feuille.nom,
        fillColor: feuille.absent ? "#CCCCCC" : "#FFFFFF",
      },
      {
        text: feuille.feuilleDeRoute.noprojet,
        fillColor: feuille.absent ? "#CCCCCC" : "#FFFFFF",
      },
      {
        text: feuille.perdiem,
        fillColor: feuille.absent ? "#CCCCCC" : "#FFFFFF",
      },
      {
        text: feuille.debut,
        fillColor: feuille.absent ? "#CCCCCC" : "#FFFFFF",
      },
      {
        text: feuille.fin,
        fillColor: feuille.absent ? "#CCCCCC" : "#FFFFFF",
      },
      {
        text: feuille.repas,
        fillColor: feuille.absent ? "#CCCCCC" : "#FFFFFF",
      },
      {
        text: feuille.total,
        fillColor: feuille.absent ? "#CCCCCC" : "#FFFFFF",
      },
    ]);
  });
  return {
    stack: [
      // { text: "Employés", bold: true, fontSize: 18 },
      {
        margin: [0, 0, 0, 20],
        table: {
          headerRows: 1,
          dontBreakRows: true,
          widths: ["auto", "*", "auto", "auto", "auto", "auto", "auto", "auto"],
          body: [
            [
              { text: "Date", bold: true, fillColor: "#CCCCCC" },
              { text: "Nom", bold: true, fillColor: "#CCCCCC" },
              {
                text: "Projet",
                bold: true,
                fillColor: "#CCCCCC",
              },
              {
                text: "Perdiem",
                bold: true,
                fillColor: "#CCCCCC",
              },
              {
                text: "Début",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
              {
                text: "Fin",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
              {
                text: "Repas",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
              {
                text: "Total",
                bold: true,
                fillColor: "#CCCCCC",
                alignment: "center",
              },
            ],
            ...table,
          ],
        },
      },
    ],
  };
};

const I18n = (title) => {
  switch (title) {
    case "premarquage":
      return { name: "Prémarquage", couleur: false };
    case "effacement":
      return { name: "Effacement", couleur: false };
    case "marquagecourteduree":
      return { name: "Marquage Courte Durée", couleur: true };
    case "marquagemoyenneduree":
      return { name: "Marquage Moyenne Durée", couleur: true };
    case "marquagelongueduree":
      return { name: "Marquage Longue Durée", couleur: true };
    case "mma":
      return { name: "MMA", couleur: true };
    case "bandesrugueuses":
      return { name: "Bandes Rugueuses", couleur: false };
    case "diversetmobilisation":
      return { name: "Divers & Mobilisations", couleur: false };
    case "todo":
      return { name: "À faire", couleur: false };
    case "complete":
      return { name: "Terminé", couleur: false };
    case "approved":
      return { name: "Approuvée", couleur: false };
    case "toaccount":
      return { name: "À comptabiliser", couleur: false };
    case "accounted":
      return { name: "Comptabilisée", couleur: false };
    case "archived":
      return { name: "Archivée", couleur: false };
  }
};

const capitalizeFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export {
  generateFeuilleDeRoutePDF,
  generateMultiplePDF,
  generateFeuillesDeTemps,
};
