import { Table, Space, Spin, Input, Button, Popconfirm } from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useState, useContext, useEffect } from "react";
import { Context } from "../utils/Context";
import {
  getComments,
  addComment,
  deleteComment,
} from "../modules/commentaires";

dayjs.locale("fr");

const Commentaires = ({
  loading,
  feuilleDeRoute,
  feuilleDeRouteCommentaires,
}) => {
  const [comments, setComments] = useState(feuilleDeRouteCommentaires);
  const [newComment, setNewComment] = useState("");
  const { loggedUser } = useContext(Context);

  const fetchComments = async () => {
    try {
      const data = await getComments(feuilleDeRoute.id);
      if (data.success) {
        setComments(data.comments);
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    try {
      const data = await addComment({
        idfeuillederoute: feuilleDeRoute.id,
        user:
          loggedUser?.firstname + " " + loggedUser?.lastname ||
          "Utilisateur inconnu",
        commentaire: newComment,
      });

      if (data.success) {
        setNewComment("");
        fetchComments();
      }
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const data = await deleteComment(commentId);
      if (data.success) {
        fetchComments();
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const columns = [
    {
      title: "Utilisateur",
      dataIndex: "user",
      key: "user",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Commentaire",
      dataIndex: "commentaire",
      key: "commentaire",
      render: (text) => {
        return <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) =>
        text ? dayjs(text).format("ddd DD MMMM YYYY [à] HH:mm:ss") : "",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => {
        const canDelete =
          record.user === loggedUser?.firstname + " " + loggedUser?.lastname ||
          loggedUser?.groupe === "Master" ||
          loggedUser?.groupe === "Administrateur";

        return (
          <Space size="middle">
            {record.key === "new" ? (
              <a onClick={handleAddComment}>
                <SaveOutlined />
              </a>
            ) : canDelete ? (
              <Popconfirm
                title="Supprimer le commentaire?"
                onConfirm={() => handleDeleteComment(record._id)}
                okText="Oui"
                cancelText="Non">
                <a>
                  <DeleteOutlined style={{ color: "#ff4d4f" }} />
                </a>
              </Popconfirm>
            ) : null}
          </Space>
        );
      },
    },
  ];

  const newCommentRow = {
    key: "new",
    user: loggedUser?.firstname + " " + loggedUser?.lastname,
    commentaire: (
      <Input.TextArea
        placeholder="Ajouter un commentaire..."
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && e.ctrlKey) {
            e.preventDefault();
            handleAddComment();
          }
        }}
        autoSize={{ minRows: 1, maxRows: 6 }}
        style={{ resize: "vertical" }}
      />
    ),
    date: "",
    action: (
      <Button
        type="primary"
        onClick={handleAddComment}
        disabled={!newComment.trim()}>
        Ajouter
      </Button>
    ),
  };

  return (
    <>
      {loading ? (
        <Spin style={{ margin: "0 auto", display: "block" }} />
      ) : (
        <Table
          columns={columns}
          dataSource={[
            newCommentRow,
            ...comments.sort((a, b) => new Date(b.date) - new Date(a.date)),
          ]}
          pagination={false}
          rowKey={(record) => record._id || record.key}
        />
      )}
    </>
  );
};

export default Commentaires;
