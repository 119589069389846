import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const getToken = () => {
  const token = cookies.get("token") || "";
  return token;
};

export const getComments = async (idfeuillederoute) => {
  const token = getToken();
  try {
    const response = await axios.get(
      `/db/getcomments?idfeuillederoute=${idfeuillederoute}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error fetching comments:", err);
    if (err.response?.status === 401) {
      window.location.href = "/login";
    }
    return { success: false, error: err.message };
  }
};

export const addComment = async ({ idfeuillederoute, user, commentaire }) => {
  const token = getToken();
  try {
    const response = await axios.post(
      "/db/addcomment",
      {
        idfeuillederoute,
        user,
        commentaire,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    console.error("Error adding comment:", err);
    if (err.response?.status === 401) {
      window.location.href = "/login";
    }
    return { success: false, error: err.message };
  }
};

export const deleteComment = async (commentId) => {
  const token = getToken();
  try {
    const response = await axios.delete("/db/deletecomment", {
      data: { commentId },
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  } catch (err) {
    console.error("Error deleting comment:", err);
    if (err.response?.status === 401) {
      window.location.href = "/login";
    }
    return { success: false, error: err.message };
  }
};
