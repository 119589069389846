import { AsYouType } from "libphonenumber-js";
import {
  Descriptions,
  Space,
  Form,
  Input,
  Checkbox,
  Button,
  Card,
  Typography,
  Spin,
  Divider,
  Image,
} from "antd";
import {
  FaTruck,
  FaHammer,
  FaUser,
  FaLuggageCart,
  FaArrowLeft,
  FaClock,
  FaMagic,
  FaLightbulb,
  FaStar,
  FaMapMarkerAlt,
  FaUserAlt,
  FaPhoneAlt,
  FaComments,
  FaBook,
  FaEnvelope,
  FaPhotoVideo,
  FaCheck,
  FaTemperatureLow,
} from "react-icons/fa";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { GiSteeringWheel, GiWaterDrop, GiBroom } from "react-icons/gi";
import Ressource from "../components/Ressource";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
dayjs.locale("fr");

const Resume = ({
  feuilleDeRoute,
  feuilleDeRouteMainDoeuvres,
  feuilleDeRouteVehicules,
  loading,
}) => {
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Descriptions
        bordered
        column={1}
        labelStyle={{ fontWeight: "bold" }}
        style={{ backgroundColor: "white" }}>
        <Descriptions.Item label="Date">
          {dayjs(feuilleDeRoute?.date).format("D MMMM YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label="Client">
          {feuilleDeRoute?.client}
        </Descriptions.Item>
        <Descriptions.Item label="Projet">
          {feuilleDeRoute?.noprojet}-{feuilleDeRoute?.descriptionprojet}
        </Descriptions.Item>
        <Descriptions.Item label="Phase">
          {feuilleDeRoute?.descriptionphase}
        </Descriptions.Item>
        <Descriptions.Item label="Chargé de projets">
          {feuilleDeRoute?.chargedeprojet}
        </Descriptions.Item>
      </Descriptions>
      <Card
        title="Informations"
        headStyle={{
          backgroundColor: "#001529",
          width: "100%",
          height: 50,
          padding: "0px 24px",
          display: "flex",
          alignItems: "center",
          color: "white",
        }}
        bodyStyle={{
          paddingTop: 15,
          paddingBottom: 15,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        {!loading ? (
          <Space direction="vertical" style={{ width: "100%" }}>
            <Divider orientation="left">Bureau</Divider>
            <Space align="center">
              <FaMapMarkerAlt />
              <strong>Localisation:</strong>
              <a
                href={
                  feuilleDeRoute.localisation?.includes("http")
                    ? null
                    : `https://maps.google.com/?q=${feuilleDeRoute.localisation}`
                }>
                {feuilleDeRoute.localisation}
              </a>
            </Space>
            <Space align="center">
              <FaUserAlt />
              <strong>Nom du client:</strong>
              {feuilleDeRoute.nomclient}
            </Space>
            <Space align="center">
              <FaUserAlt />
              <strong>Téléphone du client:</strong>
              <a
                href={`tel:${feuilleDeRoute.numeroclient?.replace(
                  /[ ()-]/g,
                  ""
                )}`}>
                {new AsYouType("CA").input(feuilleDeRoute.numeroclient || "")}
              </a>
            </Space>
            <Space align="center">
              <FaClock />
              <strong>Heure d'arrivée au chantier:</strong>
              {feuilleDeRoute.heurearriveechantier}
            </Space>
            <Space align="top">
              <FaBook />
              <strong>Documents:</strong>
              <Space direction="vertical">
                {feuilleDeRoute.fichiers?.map((file, i) => (
                  <a href={file.url} target="_blank" key={i}>
                    <Button type="default">{file.name}</Button>
                  </a>
                ))}
              </Space>
            </Space>
            <Space align="top">
              <FaComments />
              <strong>Commentaires:</strong>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {feuilleDeRoute.commentaires}
              </div>
            </Space>
            <Divider orientation="left">Chantier</Divider>
            <Space align="center">
              <FaCheck />
              <strong>Travaux terminés?</strong>
              {feuilleDeRoute.travauxcompletes}
            </Space>
            <Space align="center">
              <FaClock />
              <strong>Heure d'arrivée au chantier:</strong>
              {feuilleDeRoute.heurereellearriveechantier}
            </Space>
            <Space align="center">
              <FaClock />
              <strong>Heure de départ du chantier:</strong>
              {feuilleDeRoute.heurereelledepartchantier}
            </Space>
            <Space align="center">
              <FaTemperatureLow />
              <strong>Température:</strong>
              {feuilleDeRoute.temperature}
            </Space>
            <Space align="center">
              <TiWeatherPartlySunny />
              <strong>Météo:</strong>
              {feuilleDeRoute.meteo}
            </Space>
            <Space align="center">
              <GiWaterDrop />
              <strong>Humidité de la chausée:</strong>
              {feuilleDeRoute.chausseehumiditee}
            </Space>
            <Space align="center">
              <GiBroom />
              <strong>Propreté de la chausée:</strong>
              {feuilleDeRoute.chausseeproprete}
            </Space>
            <Space align="center" wrap size={[8, 8]}>
              <FaPhotoVideo />
              <strong>Photos:</strong>
              {feuilleDeRoute.photoschantier?.map((photo, i) => (
                <Image width={200} src={photo.url} key={i} />
              ))}
            </Space>
            <Space align="center">
              <FaUserAlt />
              <strong>Nom du client:</strong>
              {feuilleDeRoute.nomclientchantier}
            </Space>
            <Space align="center">
              <FaPhoneAlt />
              <strong>Numéro du client:</strong>
              {feuilleDeRoute.numclientchantier}
            </Space>
            <Space align="center">
              <FaEnvelope />
              <strong>Courriel du client:</strong>
              {feuilleDeRoute.emailclientchantier}
            </Space>
            <Space align="center">
              <FaComments />
              <strong>Commentaires:</strong>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {feuilleDeRoute.commentaireschantier}
              </div>
            </Space>
          </Space>
        ) : (
          <Spin />
        )}
      </Card>
      <Card
        title="Équipe"
        headStyle={{
          backgroundColor: "#001529",
          width: "100%",
          height: 50,
          padding: "0px 24px",
          display: "flex",
          alignItems: "center",
          color: "white",
        }}
        bodyStyle={{
          paddingTop: 15,
          paddingBottom: 15,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        {!loading ? (
          <Space direction="vertical" style={{ width: "100%" }}>
            {Object.values(feuilleDeRouteVehicules).map(
              ([camion], lineIndex) => (
                <Card
                  headStyle={{ padding: "10px 24px" }}
                  key={lineIndex}
                  title={<Ressource icon={<FaTruck />} title={camion.nom} />}>
                  <Space direction="vertical">
                    <Ressource icon={<FaClock />} title={camion.depart} />
                    <Ressource icon={<FaLightbulb />} title={camion.remarque} />
                    {JSON.parse(camion.remorques)?.map((remorque, index) => (
                      <Ressource
                        key={"remorque" + index}
                        icon={<FaLuggageCart />}
                        title={remorque}
                      />
                    ))}
                    {feuilleDeRouteMainDoeuvres[camion.idcamion]?.map(
                      (employe, index) => (
                        <Ressource
                          key={"employe" + index}
                          icon={<FaUser />}
                          rightIcon={
                            <>
                              {employe.contremaitre ? <FaStar /> : null}
                              {employe.chauffeur ? <GiSteeringWheel /> : null}
                            </>
                          }
                          title={employe.nom}
                        />
                      )
                    )}
                    {JSON.parse(camion.radios)?.map((radio, index) => (
                      <Ressource
                        key={"radio" + index}
                        icon={<FaHammer />}
                        title={radio}
                      />
                    ))}
                  </Space>
                </Card>
              )
            )}
          </Space>
        ) : (
          <Spin />
        )}
      </Card>
    </Space>
  );
};
export default Resume;
