import { useEffect, useState, useContext, useRef } from 'react';
import { Context } from '../utils/Context';
import {
  Typography,
  Collapse,
  Avatar,
  List,
  Form,
  Button,
  Input,
  Select,
  Row,
  Col,
  Tag,
  Spin,
  Menu,
  Dropdown,
  Modal,
  Space,
  Card,
} from 'antd';
import { loginUser } from '../modules/users';
import { useSearchParams, useNavigate } from 'react-router-dom';

const { Title } = Typography;

const LoginPage = () => {
  const context = useContext(Context);
  const { setHeaderType, successNotif, errorNotif, getContextUser } = context;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const ref = searchParams.get('ref');
  const navigate = useNavigate();
  useEffect(() => {
    setHeaderType('login');
  }, []);
  const onFinish = async (values) => {
    console.log('Success:', values);
    setLoading(true);
    try {
      const response = await loginUser({
        username: values.username,
        password: values.password,
      });
      if (response.success) {
        await getContextUser();
        if (ref) {
          navigate(ref);
        } else {
          navigate('/');
        }
      }
    } catch (err) {
      if (err.response?.status === 401) {
        errorNotif("Le nom d'utilisateur ou le mot de passe n'existe pas");
      } else {
        errorNotif(err.response.data);
      }
    }
    setLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <div className="LoginPage">
      <div className="LoginPageContainer">
        <Card
          style={{ width: '75%', maxWidth: 500 }}
          type="inner"
          headStyle={{ backgroundColor: '#001529', color: '#fff' }}
          title={
            <Title level={4} style={{ color: '#fff' }}>
              Se connecter
            </Title>
          }>
          <Form
            disabled={loading}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            scrollToFirstError={{ behavior: 'smooth' }}
            form={form}>
            <Form.Item
              label={
                <span style={{ whiteSpace: 'normal' }}>Nom d'utilisateur</span>
              }
              name="username"
              rules={[
                {
                  required: true,
                  message: "Veuillez saisir un nom d'utilisateur!",
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              label={<span style={{ whiteSpace: 'normal' }}>Mot de passe</span>}
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Veuillez saisir un mot de passe!',
                },
              ]}>
              <Input.Password />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Se connecter
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default LoginPage;
