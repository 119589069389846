import { useState, useEffect } from 'react';
import { Card, Table, Input, Button, Popconfirm, Form } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
const dataSource = [
  {
    _id: '0',
    description: 'Disques jaunes',
    unite: 'u',
  },
  {
    _id: '1',
    description: 'Disques blancs',
    unite: 'u',
  },
  {
    _id: '2',
    description: 'Délinéateurs jaunes',
    unite: 'u',
  },
  {
    _id: '3',
    description: 'Délinéateurs blancs',
    unite: 'u',
  },
  {
    _id: '4',
    description: 'Cannette prémarquage',
    unite: 'u',
  },
];
const countSource = dataSource.length;

const Premarquage = ({ premarquageData, setPremarquageData }) => {
  const [count, setCount] = useState(countSource);
  useEffect(() => {
    if (!premarquageData.length) {
      setPremarquageData([...dataSource]);
    }
  }, [premarquageData]);
  const handleDelete = (id) => {
    const _data = [...premarquageData];
    setPremarquageData(_data.filter((item) => item._id !== id));
  };
  const handleAdd = () => {
    const newData = {
      key: count,
    };
    setPremarquageData([...premarquageData, newData]);
    setCount((count) => count + 1);
  };
  const onInputChange = (key, index) => (e) => {
    const newData = [...premarquageData];
    newData[index][key] = e.target.value;
    setPremarquageData(newData);
  };

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '70%',
      render: (text, record, index) => (
        <Input value={text} onChange={onInputChange('description', index)} />
      ),
    },
    {
      title: 'Unité',
      dataIndex: 'unite',
      key: 'unite',
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={onInputChange('unite', index)}
          style={{ textAlign: 'center' }}
        />
      ),
      align: 'center',
    },
    {
      title: 'Quantités estimées',
      dataIndex: 'quantiteest',
      key: 'quantiteest',
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={onInputChange('quantiteest', index)}
          style={{ textAlign: 'center' }}
        />
      ),
      align: 'center',
    },
    {
      title: 'Supprimer?',
      dataIndex: 'supprimer',
      key: 'supprimer',
      align: 'center',
      render: (_, record, index) =>
        premarquageData.length >= 1 ? (
          <Popconfirm
            title="Êtes-vous sur de vouloir supprimer la ligne?"
            onConfirm={() => handleDelete(record._id)}>
            <Button icon={<DeleteOutlined />} type="link"></Button>
          </Popconfirm>
        ) : null,
    },
  ];
  return (
    <Card
      title="Prémarquage"
      type="inner"
      headStyle={{ backgroundColor: '#001529', color: '#fff' }}>
      <Table
        pagination={false}
        bordered
        dataSource={premarquageData}
        columns={columns}
        rowKey="_id"
      />
      <Button
        type="primary"
        style={{ marginTop: 10 }}
        icon={<PlusOutlined />}
        onClick={handleAdd}>
        Ajouter une ligne
      </Button>
    </Card>
  );
};

export default Premarquage;
