import { useEffect, useState, useContext, useRef } from "react";
import {
  Button,
  Typography,
  Space,
  Spin,
  Checkbox,
  BackTop,
  Card,
  Affix,
  message,
  Select,
} from "antd";
import { FaArrowLeft } from "react-icons/fa";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  getphasefromdateandid,
  getcommandeventefromproject,
  savefeuillederoute,
  getfeuillederoute,
  deletefeuillederoute,
  getclientsfromnoproject,
} from "../modules/api";
import { useNavigate, useParams } from "react-router-dom";
import Premarquage from "../components/Premarquage";
import MarquageCourteDuree from "../components/MarquageCourteDuree";
import Effacement from "../components/Effacement";
import MarquageMoyenneDuree from "../components/MarquageMoyenneDuree";
import MarquageLongueDuree from "../components/MarquageLongueDuree";
import MMA from "../components/MMA";
import BandesRugueuses from "../components/BandesRugueuses";
import DiversEtMobilisation from "../components/DiversEtMobilisation";
import Informations from "../components/Informations";
import { Context } from "../utils/Context";
const { Option } = Select;
const { Title } = Typography;
const checkBoxOptions = [
  { label: "Prémarquage", value: "premarquage" },
  { label: "Marquage Courte Durée", value: "marquagecourteduree" },
  { label: "Effacement", value: "effacement" },
  { label: "Marquage Moyenne Durée", value: "marquagemoyenneduree" },
  { label: "Marquage Longue Durée", value: "marquagelongueduree" },
  { label: "MMA", value: "mma" },
  { label: "Bandes Rugueuses", value: "bandesrugueuses" },
  { label: "Divers & Mobilisations", value: "diversetmobilisations" },
];
const FeuilleDeRouteCreationPage = () => {
  let navigate = useNavigate();
  let params = useParams();
  const [data, setData] = useState({});
  const [commandeVente, setCommandeVente] = useState([]);
  const [loading, setLoading] = useState(false);
  const [container, setContainer] = useState(null);
  const [checkedValues, setCheckedValues] = useState([]);
  const [premarquageData, setPremarquageData] = useState([]);
  const [marquagecourtedureeData, setMarquagecourtedureeData] = useState([]);
  const [effacementData, setEffacementData] = useState([]);
  const [marquagemoyennedureeData, setMarquagemoyennedureeData] = useState([]);
  const [marquagelonguedureeData, setMarquagelonguedureeData] = useState([]);
  const [mmaData, setMMAData] = useState([]);
  const [bandesrugueusesData, setBandesrugueusesData] = useState([]);
  const [diversetmobilisationData, setDiversetmobilisationData] = useState([]);
  const [contremaitre, setContremaitre] = useState(null);
  const [employes, setEmployes] = useState([]);
  const [vehicules, setVehicules] = useState([]);
  const [client, setClient] = useState(null);
  const [selectedFichiers, setSelectedFichiers] = useState([]);
  const informations = useRef({});
  const context = useContext(Context);
  const { setHeaderType, setHeaderTitle, setHeaderLoading } = context;
  console.log({ employes });
  useEffect(() => {
    setHeaderType("feuillederoute");
  }, []);
  useEffect(() => {
    getData();
  }, [params.phaseid]);
  useEffect(() => {
    setHeaderTitle(
      `${data.NoProjet} - ${data.DsProjet} - ${moment(params.date).format(
        "dddd DD MMMM YYYY"
      )} - ${data.DsPhase}`
    );
  }, [data]);
  const getData = async () => {
    setLoading(true);
    setHeaderLoading(true);
    try {
      let response = await getphasefromdateandid(params.date, params.phaseid);
      console.log(response);
      setData(response);

      let _employes = [];
      let _vehicules = [];
      let contremaitre = null;
      const noProjet = response.NoProjet;
      let client = await getclientsfromnoproject(JSON.stringify([noProjet]));
      setClient(Object.values(client)[0]);
      response.PProjPhasePlanif.forEach((camion) => {
        let vehicule = {
          Camion: camion.Camion,
          Id: camion.KIPRODUITCAMION,
          Depart: camion.DEPART,
          Remarque: camion.Remarque,
          Remorques: [],
          Radios: [],
        };
        camion.Ressource.Employe.forEach((employe) => {
          if (employe.FlContremaitre) {
            contremaitre = employe.Nom;
          }
          _employes.push({
            ...employe,
            IdCamion: camion.KIPRODUITCAMION,
          });
        });
        camion.Ressource.Radio.forEach((radio) => {
          vehicule.Radios.push(radio.Nom);
        });
        camion.Ressource.Remorque.forEach((remorque) => {
          vehicule.Remorques.push(remorque.Nom);
        });
        _vehicules.push(vehicule);
      });
      setEmployes(_employes);
      setVehicules(_vehicules);
      response = await getfeuillederoute(params.date + "-" + params.phaseid);
      console.log(response);
      if (response.feuilleDeRoute?.contremaitre) {
        contremaitre = response.feuilleDeRoute.contremaitre;
      }
      if (_employes.length > 0) {
        if (contremaitre) {
          setContremaitre(contremaitre);
        } else {
          setContremaitre(_employes[0].Nom);
        }
      }
      if (response.success) {
        setExistingFeuilleDeRoute(response);
      }
      response = await getcommandeventefromproject(noProjet);
      setCommandeVente(response);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    setHeaderLoading(false);
  };
  const setExistingFeuilleDeRoute = ({
    feuilleDeRoute,
    feuilleDeRouteQuantites,
  }) => {
    let newCheckedValues = [...checkedValues];
    if (feuilleDeRouteQuantites.premarquage) {
      newCheckedValues.push("premarquage");
      setPremarquageData(feuilleDeRouteQuantites.premarquage);
    }
    if (feuilleDeRouteQuantites.marquagecourteduree) {
      newCheckedValues.push("marquagecourteduree");
      setMarquagecourtedureeData(feuilleDeRouteQuantites.marquagecourteduree);
    }
    if (feuilleDeRouteQuantites.effacement) {
      newCheckedValues.push("effacement");
      setEffacementData(feuilleDeRouteQuantites.effacement);
    }
    if (feuilleDeRouteQuantites.marquagemoyenneduree) {
      newCheckedValues.push("marquagemoyenneduree");
      setMarquagemoyennedureeData(feuilleDeRouteQuantites.marquagemoyenneduree);
    }
    if (feuilleDeRouteQuantites.marquagelongueduree) {
      newCheckedValues.push("marquagelongueduree");
      setMarquagelonguedureeData(feuilleDeRouteQuantites.marquagelongueduree);
    }
    if (feuilleDeRouteQuantites.mma) {
      newCheckedValues.push("mma");
      setMMAData(feuilleDeRouteQuantites.mma);
    }
    if (feuilleDeRouteQuantites.bandesrugueuses) {
      newCheckedValues.push("bandesrugueuses");
      setBandesrugueusesData(feuilleDeRouteQuantites.bandesrugueuses);
    }
    if (feuilleDeRouteQuantites.diversetmobilisation) {
      newCheckedValues.push("diversetmobilisations");
      setDiversetmobilisationData(feuilleDeRouteQuantites.diversetmobilisation);
    }
    setSelectedFichiers(feuilleDeRoute?.fichiers || []);
    setCheckedValues(newCheckedValues);

    // Handle both old and new data structures
    let localisations = feuilleDeRoute?.localisations;
    let contacts = feuilleDeRoute?.contacts;

    // If we have old structure data, convert it to new structure
    if (
      feuilleDeRoute?.localisation ||
      !localisations ||
      localisations.length === 0
    ) {
      localisations = feuilleDeRoute?.localisation
        ? [feuilleDeRoute.localisation]
        : [""];
    }
    if (
      feuilleDeRoute?.nomclient ||
      feuilleDeRoute?.numeroclient ||
      !contacts ||
      contacts.length === 0
    ) {
      contacts = [
        {
          role: feuilleDeRoute?.nomclient ? "Client" : "",
          nomclient: feuilleDeRoute?.nomclient || "",
          numeroclient: feuilleDeRoute?.numeroclient || "",
        },
      ];
    }

    informations.current = {
      localisations,
      contacts,
      heurearriveechantier: feuilleDeRoute?.heurearriveechantier || "",
      commentaires: feuilleDeRoute?.commentaires || "",
    };
  };
  const onChange = (checkedValues) => {
    setCheckedValues(checkedValues);
  };
  const handleSelectChange = (value) => {
    setContremaitre(value);
  };
  const setInformations = (name, data) => {
    console.log({ name, data });
    // For contacts and localisations, ensure we're setting an array
    // if (name === "contacts" || name === "localisations") {
    //   informations.current = {
    //     ...informations.current,
    //     [name]: Array.isArray(data) ? data : [data].filter(Boolean),
    //   };
    // } else {
    informations.current = { ...informations.current, [name]: data };
    // }
    console.log("informations.current", informations.current);
  };
  return (
    <div className="FeuilleDeRoutePage">
      <div className="FeuilleDeRouteContainer" ref={setContainer}>
        {container && <BackTop target={() => container} />}
        <div className="FeuilleDeRouteContent">
          <Space direction="vertical" style={{ width: "100%" }}>
            <Affix target={() => container}>
              <Card>
                <Checkbox.Group
                  options={checkBoxOptions}
                  onChange={onChange}
                  value={checkedValues}
                />
                <div style={{ marginTop: 10 }}>
                  {employes.length > 0 && (
                    <Space align="baseline">
                      <span style={{ fontWeight: "bold" }}>Contremaître:</span>
                      <Select
                        style={{ width: 300 }}
                        value={contremaitre}
                        onChange={handleSelectChange}>
                        {employes.map((employe, index) => (
                          <Option value={employe.Nom} key={index}>
                            {employe.Nom}
                          </Option>
                        ))}
                      </Select>
                    </Space>
                  )}
                </div>
              </Card>
            </Affix>
            <Informations
              informations={informations}
              setInformations={setInformations}
              data={data}
              selectedFichiers={selectedFichiers}
            />
            {checkedValues.includes("premarquage") && (
              <Premarquage
                premarquageData={premarquageData}
                setPremarquageData={setPremarquageData}
              />
            )}
            {checkedValues.includes("marquagecourteduree") && (
              <MarquageCourteDuree
                commandeVente={commandeVente}
                marquagecourtedureeData={marquagecourtedureeData}
                setMarquagecourtedureeData={setMarquagecourtedureeData}
              />
            )}
            {checkedValues.includes("effacement") && (
              <Effacement
                commandeVente={commandeVente}
                effacementData={effacementData}
                setEffacementData={setEffacementData}
              />
            )}
            {checkedValues.includes("marquagemoyenneduree") && (
              <MarquageMoyenneDuree
                commandeVente={commandeVente}
                marquagemoyennedureeData={marquagemoyennedureeData}
                setMarquagemoyennedureeData={setMarquagemoyennedureeData}
              />
            )}
            {checkedValues.includes("marquagelongueduree") && (
              <MarquageLongueDuree
                commandeVente={commandeVente}
                marquagelonguedureeData={marquagelonguedureeData}
                setMarquagelonguedureeData={setMarquagelonguedureeData}
              />
            )}
            {checkedValues.includes("mma") && (
              <MMA
                commandeVente={commandeVente}
                mmaData={mmaData}
                setMMAData={setMMAData}
              />
            )}
            {checkedValues.includes("bandesrugueuses") && (
              <BandesRugueuses
                commandeVente={commandeVente}
                bandesrugueusesData={bandesrugueusesData}
                setBandesrugueusesData={setBandesrugueusesData}
              />
            )}
            {checkedValues.includes("diversetmobilisations") && (
              <DiversEtMobilisation
                commandeVente={commandeVente}
                diversetmobilisationData={diversetmobilisationData}
                setDiversetmobilisationData={setDiversetmobilisationData}
              />
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}>
              <Space>
                <Button
                  loading={loading}
                  type="primary"
                  icon={<SaveOutlined />}
                  style={{ width: 200 }}
                  onClick={async () => {
                    setLoading(true);
                    // Build newData object from checked values
                    const newData = checkedValues.reduce((acc, value) => {
                      const dataMap = {
                        premarquage: premarquageData,
                        marquagecourteduree: marquagecourtedureeData,
                        effacement: effacementData,
                        marquagemoyenneduree: marquagemoyennedureeData,
                        marquagelongueduree: marquagelonguedureeData,
                        mma: mmaData,
                        bandesrugueuses: bandesrugueusesData,
                        diversetmobilisations: {
                          key: "diversetmobilisation",
                          data: diversetmobilisationData,
                        },
                      };

                      const item = dataMap[value];
                      if (item?.key) {
                        // Handle special case for diversetmobilisations
                        acc[item.key] = item.data;
                      } else {
                        acc[value] = item;
                      }
                      return acc;
                    }, {});

                    try {
                      const response = await savefeuillederoute(
                        params.date + "-" + params.phaseid,
                        contremaitre,
                        params.date,
                        params.phaseid,
                        newData,
                        data.NoProjet,
                        data.DsProjet,
                        data.DsPhase,
                        client,
                        data.Chargedeprojet,
                        employes,
                        vehicules,
                        informations.current.localisations || [],
                        informations.current.contacts || [],
                        informations.current.heurearriveechantier,
                        informations.current.commentaires,
                        informations.current.fichiers || []
                      );

                      if (response.success) {
                        message.success(
                          "La feuille de route a été enregistrée !"
                        );
                      } else {
                        message.error(response.error);
                      }
                    } catch (error) {
                      message.error(
                        "Une erreur est survenue lors de l'enregistrement"
                      );
                      console.error(error);
                    } finally {
                      setLoading(false);
                    }
                  }}>
                  Sauvegarder
                </Button>
                <Button
                  loading={loading}
                  type="danger"
                  icon={<DeleteOutlined />}
                  style={{ width: 200 }}
                  onClick={async () => {
                    setLoading(true);
                    let response = await deletefeuillederoute(
                      params.date + "-" + params.phaseid
                    );
                    if (response.success) {
                      message.success(
                        "La feuille de route a été supprimée !",
                        1,
                        () => window.location.reload()
                      );
                    }
                    setLoading(false);
                  }}>
                  Supprimer
                </Button>
              </Space>
            </div>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default FeuilleDeRouteCreationPage;
