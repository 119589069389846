import { Form, Input, Spin, Card } from "antd";
import { useContext } from "react";
import { Context } from "../utils/Context";
import useDebounce from "../utils/useDebounce";
import { updateComptabilite } from "../modules/comptabilite";

const Comptabilite = ({
  loading,
  feuilleDeRoute,
  feuilleDeRouteComptabilite,
  setFeuilleDeRouteComptabilite,
}) => {
  const context = useContext(Context);
  const [form] = Form.useForm();

  const debouncedUpdate = useDebounce((values) => {
    updateComptabilite({
      idfeuillederoute: feuilleDeRoute.id,
      ...values,
    });
  }, 500);

  const handleValuesChange = (_, values) => {
    debouncedUpdate(values);
    setFeuilleDeRouteComptabilite(values);
  };

  const isDisabled = (field) => {
    if (feuilleDeRoute.status === "archived") return true;
    if (
      !["Master", "Administrateur", "Comptabilite", "Operations"].includes(
        context.loggedUser?.groupe
      )
    )
      return true;
    if (
      ["Master", "Administrateur", "Comptabilite"].includes(
        context.loggedUser?.groupe
      )
    )
      return false;

    if (
      context.loggedUser?.groupe === "Operations" &&
      feuilleDeRoute.status === "approved" &&
      field === "feuilledetemps"
    )
      return false;

    return true;
  };

  return (
    <>
      {loading ? (
        <Spin style={{ margin: "0 auto", display: "block" }} />
      ) : (
        <Card>
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onValuesChange={handleValuesChange}
            initialValues={feuilleDeRouteComptabilite}
            style={{ maxWidth: 600 }}>
            <Form.Item name="feuilledetemps" label="Feuille de Temps">
              <Input
                placeholder="Entrer initiales..."
                disabled={isDisabled("feuilledetemps")}
              />
            </Form.Item>
            <Form.Item name="factureinterne" label="Facture Interne">
              <Input
                placeholder="Entrer facture interne..."
                disabled={isDisabled("factureinterne")}
              />
            </Form.Item>
            <Form.Item name="bondelivraison" label="Bon de Livraison">
              <Input
                placeholder="Entrer bon de livraison..."
                disabled={isDisabled("bondelivraison")}
              />
            </Form.Item>
            <Form.Item name="facturevente" label="Facture Vente">
              <Input
                placeholder="Entrer facture vente..."
                disabled={isDisabled("facturevente")}
              />
            </Form.Item>
          </Form>
        </Card>
      )}
    </>
  );
};

export default Comptabilite;
