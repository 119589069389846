import { useEffect, useState, useContext, useRef } from "react";
import {
  getfeuillederoute,
  sendfeuillederoute,
  getemployeslist,
  changestatusfeuillederoute,
  getnodonneur,
} from "../modules/api";
import { useParams } from "react-router-dom";
import { Context } from "../utils/Context";
import moment from "moment";
import { Tabs, Space, Badge, Select, notification, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import Resume from "../components/Resume";
import Ressources from "../components/Ressources";
import Quantites from "../components/Quantites";
import Peinture from "../components/Peinture";
import Commentaires from "../components/Commentaires";
import Comptabilite from "../components/Comptabilite";
import {
  MdLibraryBooks,
  MdLocalShipping,
  MdOutlineTag,
  MdFormatPaint,
  MdAccountBalance,
} from "react-icons/md";
import { FaCheckCircle, FaComments } from "react-icons/fa";
import { generateFeuilleDeRoutePDF } from "../modules/pdf";

const { TabPane } = Tabs;
const { Option } = Select;

const FeuilleDeRouteDetailPage = () => {
  const [loading, setLoading] = useState(false);
  const { phaseid, date } = useParams();
  const [feuilleDeRoute, setFeuilleDeRoute] = useState({});
  const [feuilleDeRouteQuantites, setFeuilleDeRouteQuantites] = useState({});
  const [feuilleDeRouteMainDoeuvres, setFeuilleDeRouteMainDoeuvres] = useState(
    {}
  );
  const [feuilleDeRouteVehicules, setFeuilleDeRouteVehicules] = useState({});
  const [feuilleDeRoutePeinture, setFeuilleDeRoutePeinture] = useState({});
  const [feuilleDeRouteCommentaires, setFeuilleDeRouteCommentaires] = useState(
    []
  );
  const [feuilleDeRouteComptabilite, setFeuilleDeRouteComptabilite] = useState(
    {}
  );
  const [ressourcesSaved, setRessourcesSaved] = useState(false);
  let [quantitesSaved, setQuantitesSaved] = useState(false);
  let [peintureSaved, setPeintureSaved] = useState(false);
  const [status, setStatus] = useState("");
  const feuilleDeRouteToSend = useRef();
  const [employesList, setEmployesList] = useState([]);
  const [noDonneur, setNoDonneur] = useState(undefined);
  const [loadingPrint, setLoadingPrint] = useState(false);

  const context = useContext(Context);
  const { setHeaderType, setHeaderTitle, setHeaderLoading } = context;
  useEffect(() => {
    setHeaderType("feuillederoute");
    getFeuilleDeRoute();
    getEmployesList();
  }, []);
  const fetchFeuilleDeRoute = async () => {
    const response = await getfeuillederoute(`${date}-${phaseid}`);
    setFeuilleDeRoute(response.feuilleDeRoute);
    setFeuilleDeRouteQuantites(response.feuilleDeRouteQuantites);
    setFeuilleDeRouteMainDoeuvres(response.feuilleDeRouteMainDoeuvre);
    setFeuilleDeRouteVehicules(response.feuilleDeRouteVehicule);
    setFeuilleDeRoutePeinture(response.feuilleDeRoutePeinture);
    setFeuilleDeRouteCommentaires(response.feuilleDeRouteCommentaires);
    setFeuilleDeRouteComptabilite(response.feuilleDeRouteComptabilite);
    setStatus(response.feuilleDeRoute.status);
    getNoDonneur(response.feuilleDeRoute.noprojet);
    setHeaderTitle(
      `${response.feuilleDeRoute.noprojet} - ${
        response.feuilleDeRoute.descriptionprojet
      } - ${moment(date).format("dddd DD MMMM YYYY")} - ${
        response.feuilleDeRoute.descriptionphase
      }`
    );
    console.log(response);
    return response;
  };
  const getFeuilleDeRoute = async () => {
    setLoading(true);
    await fetchFeuilleDeRoute();
    setLoading(false);
    feuilleDeRouteToSend.current = {};
    setRessourcesSaved(false);
    setQuantitesSaved(false);
    setPeintureSaved(false);
  };
  const saveFeuilleDeRouteData = (categorie, value) => {
    // console.log({ categorie, value });
    if (categorie === "ressources") {
      setRessourcesSaved(value ? true : false);
    } else if (categorie === "quantites") {
      setQuantitesSaved(value ? true : false);
    } else if (categorie === "peinture") {
      setPeintureSaved(value ? true : false);
    }
    feuilleDeRouteToSend.current = {
      ...feuilleDeRouteToSend.current,
      [categorie]: value,
    };
  };
  const getEmployesList = async () => {
    const employesList = await getemployeslist();
    if (employesList.success) setEmployesList(employesList.msg);
  };
  const getNoDonneur = async (noprojet) => {
    const response = await getnodonneur(noprojet);
    setNoDonneur(response.msg && response.msg[0]?.NoDonneur);
  };

  const getDisabledStatus = (statusOption, currentStatus) => {
    const loggedUser = context.loggedUser;
    console.log({ statusOption, currentStatus });
    if (statusOption === currentStatus) return false;
    if (
      loggedUser?.groupe === "Master" ||
      loggedUser?.groupe === "Administrateur" ||
      loggedUser?.groupe === "Comptabilite"
    )
      return false;
    if (loggedUser?.groupe === "Operations" && statusOption === "toaccount")
      return !ressourcesSaved;
    if (loggedUser?.groupe === "Bureau" && statusOption === "approved")
      return !(ressourcesSaved && quantitesSaved && peintureSaved);
    if (loggedUser?.groupe === "Operations" && statusOption === "approved")
      return false;

    return true;
  };

  console.log({ loggedUser: context.loggedUser,ressourcesSaved, quantitesSaved, peintureSaved });

  return (
    <div className="FeuilleDeRoutePage">
      <div className="FeuilleDeRouteContainer">
        <div
          style={{
            maxWidth: 1500,
            width: "100%",
            padding: 20,
          }}>
          <Tabs
            defaultActiveKey="resume"
            tabBarStyle={{ backgroundColor: "white", padding: "0px 20px" }}
            tabBarExtraContent={
              <Space>
                <Select
                  loading={loading}
                  value={status}
                  disabled={(() => {
                    if (
                      feuilleDeRoute.status === "todo" ||
                      feuilleDeRoute.status === "complete"
                    ) {
                      return !(
                        ressourcesSaved &&
                        quantitesSaved &&
                        peintureSaved
                      );
                    }
                    return false;
                  })()}
                  onChange={async (value) => {
                    if (value !== status) {
                      if (
                        value === "toaccount" &&
                        context.loggedUser?.groupe === "Operations" &&
                        !feuilleDeRouteComptabilite.feuilledetemps
                      ) {
                        notification.error({
                          message:
                            "Veuillez entrer vos initiales dans Feuille de Temps, onglet Comptabilité",
                          placement: "top",
                        });
                        return;
                      }
                      setLoading(true);
                      try {
                        let response = await sendfeuillederoute({
                          ...feuilleDeRouteToSend.current,
                          id: feuilleDeRoute.id,
                          status: value,
                        });
                        // }
                        // else {
                        //   response = await changestatusfeuillederoute({
                        //     id: feuilleDeRoute.id,
                        //     status: value,
                        //   });
                        // }
                        if (response.success) {
                          notification.success({
                            message: "Changement de statut complété!",
                            placement: "top",
                          });
                          getFeuilleDeRoute();
                        } else {
                          notification.error({
                            message: response.error,
                            placement: "top",
                          });
                        }
                      } catch (err) {
                        notification.error({
                          message: err.message,
                          placement: "top",
                        });
                      }
                      setLoading(false);
                    }
                  }}
                  style={{
                    width: 140,
                  }}>
                  {[
                    "Master",
                    "Administrateur",
                    "Bureau",
                    "Operations",
                  ].includes(context.loggedUser?.groupe) && (
                    <>
                      <Option
                        value="todo"
                        disabled={getDisabledStatus("todo", status)}>
                        À faire
                      </Option>
                      <Option
                        value="complete"
                        disabled={getDisabledStatus("complete", status)}>
                        À approuver
                      </Option>
                    </>
                  )}
                  {[
                    "Master",
                    "Administrateur",
                    "Comptabilite",
                    "Operations",
                    "Bureau",
                  ].includes(context.loggedUser?.groupe) && (
                    <>
                      <Option
                        value="approved"
                        disabled={getDisabledStatus("approved", status)}>
                        Approuvée
                      </Option>
                    </>
                  )}

                  {[
                    "Master",
                    "Administrateur",
                    "Comptabilite",
                    "Operations",
                  ].includes(context.loggedUser?.groupe) && (
                    <>
                      <Option
                        value="toaccount"
                        disabled={getDisabledStatus("toaccount", status)}>
                        À comptabiliser
                      </Option>
                    </>
                  )}
                  {[
                    "Master",
                    "Administrateur",
                    "Comptabilite",
                    "Operations",
                  ].includes(context.loggedUser?.groupe) && (
                    <>
                      <Option
                        value="accounted"
                        disabled={getDisabledStatus("accounted", status)}>
                        Comptabilisée
                      </Option>

                      <Option
                        value="archived"
                        disabled={getDisabledStatus("archived", status)}>
                        Archivée
                      </Option>
                    </>
                  )}
                </Select>
                <Button
                  type="danger"
                  icon={<PrinterOutlined />}
                  loading={loadingPrint}
                  disabled={
                    feuilleDeRoute.status === "todo" ||
                    feuilleDeRoute.status === "complete"
                  }
                  onClick={async () => {
                    setLoadingPrint(true);
                    const response = await fetchFeuilleDeRoute();
                    try {
                      generateFeuilleDeRoutePDF(
                        response.feuilleDeRoute,
                        response.feuilleDeRouteQuantites,
                        response.feuilleDeRoutePeinture,
                        response.feuilleDeRouteMainDoeuvre,
                        response.feuilleDeRouteVehicule,
                        response.feuilleDeRouteCommentaires,
                        response.feuilleDeRouteComptabilite,
                        noDonneur
                      );
                    } catch (err) {
                      console.log({ err });
                      notification.error({
                        message: err.message,
                        placement: "top",
                      });
                    }
                    setLoadingPrint(false);
                  }}>
                  Imprimer
                </Button>
              </Space>
            }>
            <TabPane
              tab={
                <Space>
                  <MdLibraryBooks />
                  Résumé
                </Space>
              }
              key="resume">
              <Resume
                feuilleDeRoute={feuilleDeRoute}
                feuilleDeRouteMainDoeuvres={feuilleDeRouteMainDoeuvres}
                feuilleDeRouteVehicules={feuilleDeRouteVehicules}
                loading={loading}
              />
            </TabPane>
            <TabPane
              tab={
                <Badge
                  count={
                    ressourcesSaved ? (
                      <FaCheckCircle style={{ color: "green" }} />
                    ) : (
                      0
                    )
                  }
                  offset={[0, -5]}>
                  <Space>
                    <MdLocalShipping />
                    Ressources
                  </Space>
                </Badge>
              }
              key="ressources">
              <Ressources
                loading={loading}
                feuilleDeRoute={feuilleDeRoute}
                feuilleDeRouteVehicules={feuilleDeRouteVehicules}
                feuilleDeRouteMainDoeuvres={feuilleDeRouteMainDoeuvres}
                saveFeuilleDeRouteData={saveFeuilleDeRouteData}
                ressourcesSaved={ressourcesSaved}
                employesList={employesList}
              />
            </TabPane>
            <TabPane
              tab={
                <Badge
                  count={
                    quantitesSaved ? (
                      <FaCheckCircle style={{ color: "green" }} />
                    ) : (
                      0
                    )
                  }
                  offset={[0, -5]}>
                  <Space>
                    <MdOutlineTag />
                    Quantités
                  </Space>
                </Badge>
              }
              key="quantites">
              <Quantites
                loading={loading}
                feuilleDeRoute={feuilleDeRoute}
                feuilleDeRouteQuantites={feuilleDeRouteQuantites}
                saveFeuilleDeRouteData={saveFeuilleDeRouteData}
                quantitesSaved={quantitesSaved}
              />
            </TabPane>
            <TabPane
              tab={
                <Badge
                  count={
                    peintureSaved ? (
                      <FaCheckCircle style={{ color: "green" }} />
                    ) : (
                      0
                    )
                  }
                  offset={[0, -5]}>
                  <Space>
                    <MdFormatPaint />
                    Peinture
                  </Space>
                </Badge>
              }
              key="peinture">
              <Peinture
                loading={loading}
                feuilleDeRoute={feuilleDeRoute}
                feuilleDeRouteQuantites={feuilleDeRouteQuantites}
                feuilleDeRoutePeinture={feuilleDeRoutePeinture}
                saveFeuilleDeRouteData={saveFeuilleDeRouteData}
                peintureSaved={peintureSaved}
              />
            </TabPane>
            <TabPane
              tab={
                <Space>
                  <FaComments />
                  Commentaires
                </Space>
              }
              key="commentaires">
              <Commentaires
                loading={loading}
                feuilleDeRoute={feuilleDeRoute}
                feuilleDeRouteCommentaires={feuilleDeRouteCommentaires}
              />
            </TabPane>
            <TabPane
              tab={
                <Space>
                  <MdAccountBalance />
                  Comptabilité
                </Space>
              }
              key="comptabilite">
              <Comptabilite
                loading={loading}
                feuilleDeRoute={feuilleDeRoute}
                feuilleDeRouteComptabilite={feuilleDeRouteComptabilite}
                setFeuilleDeRouteComptabilite={setFeuilleDeRouteComptabilite}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default FeuilleDeRouteDetailPage;
