import { useState, useEffect } from "react";
import {
  Card,
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  AutoComplete,
  Space,
} from "antd";
import {
  FaMapMarkerAlt,
  FaUserAlt,
  FaPhoneAlt,
  FaClock,
  FaComments,
  FaBook,
  FaGraduationCap,
  FaTrash,
  FaPlus,
} from "react-icons/fa";
import MaskedInput from "antd-mask-input";
import UploadFile from "./UploadFile";
const { TextArea } = Input;
const Informations = ({
  informations,
  setInformations,
  data,
  selectedFichiers,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(informations.current);
  }, [informations.current]);
  return (
    <Card
      title="Informations"
      type="inner"
      headStyle={{ backgroundColor: "#001529", color: "#fff" }}>
      <Form
        form={form}
        name="informations"
        layout="vertical"
        onFieldsChange={(changedFields, allFields) => {
          // Process each changed field
          changedFields.forEach((changedField) => {
            const fieldPath = changedField.name;
            const fieldName = fieldPath[0];

            // For array fields (localisations and contacts), find the array field that contains all values
            if (fieldName === "localisations" || fieldName === "contacts") {
              const arrayField = allFields.find(
                (f) =>
                  Array.isArray(f.name) &&
                  f.name.length === 1 &&
                  f.name[0] === fieldName
              );

              if (arrayField?.value) {
                if (fieldName === "localisations") {
                  const transformedLocalisations = (arrayField.value || []).map(
                    (item) => ({
                      localisation:
                        item &&
                        typeof item === "object" &&
                        "localisation" in item
                          ? item.localisation || ""
                          : "",
                    })
                  );
                  setInformations(fieldName, transformedLocalisations);
                } else if (fieldName === "contacts") {
                  const transformedContacts = (arrayField.value || []).map(
                    (item) => ({
                      role:
                        item && typeof item === "object" ? item.role || "" : "",
                      nomclient:
                        item && typeof item === "object"
                          ? item.nomclient || ""
                          : "",
                      numeroclient:
                        item && typeof item === "object"
                          ? item.numeroclient || ""
                          : "",
                    })
                  );
                  setInformations(fieldName, transformedContacts);
                }
              }
            } else {
              // For single value fields, find the exact field
              const singleField = allFields.find(
                (f) =>
                  Array.isArray(f.name) &&
                  f.name.length === 1 &&
                  f.name[0] === fieldName
              );
              if (singleField) {
                setInformations(fieldName, singleField.value);
              }
            }
          });
        }}>
        <Form.List name="localisations" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <div style={{ display: "grid", gap: "8px" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr auto",
                  gap: "16px",
                }}>
                <Space align="center">
                  <FaMapMarkerAlt />
                  Localisation
                </Space>
              </div>
              {fields.map(({ key, name, ...restField }, index) => (
                <div
                  key={key}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr auto",
                    gap: "16px",
                    alignItems: "center",
                  }}>
                  <Form.Item
                    {...restField}
                    name={[name, "localisation"]}
                    style={{ marginBottom: 0 }}>
                    <Input placeholder="Localisation" style={{ height: 32 }} />
                  </Form.Item>
                  {fields.length > 1 && (
                    <Button
                      type="text"
                      onClick={() => remove(name)}
                      icon={<FaTrash />}
                    />
                  )}
                </div>
              ))}
              <Form.Item style={{ marginTop: 8 }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<FaPlus />}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 4,
                  }}>
                  Ajouter une localisation
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>

        <Form.List name="contacts" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <div style={{ display: "grid", gap: "8px" }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr auto",
                  gap: "16px",
                }}>
                <Space align="center">
                  <FaGraduationCap />
                  Rôle
                </Space>
                <Space align="center">
                  <FaUserAlt />
                  Nom du contact
                </Space>
                <Space align="center">
                  <FaPhoneAlt />
                  Téléphone du contact
                </Space>
              </div>
              {fields.map(({ key, name, ...restField }, index) => (
                <div
                  key={key}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr auto",
                    gap: "16px",
                    alignItems: "center",
                  }}>
                  <Form.Item
                    {...restField}
                    name={[name, "role"]}
                    style={{ marginBottom: 0 }}>
                    <Input placeholder="Rôle" style={{ height: 32 }} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "nomclient"]}
                    style={{ marginBottom: 0 }}>
                    <Input placeholder="Nom" style={{ height: 32 }} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "numeroclient"]}
                    style={{ marginBottom: 0 }}>
                    <Input placeholder="Téléphone" style={{ height: 32 }} />
                  </Form.Item>
                  {fields.length > 1 && (
                    <Button
                      type="text"
                      onClick={() => remove(name)}
                      icon={<FaTrash />}
                    />
                  )}
                </div>
              ))}
              <Form.Item style={{ marginTop: 8 }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<FaPlus />}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 4,
                  }}>
                  Ajouter un contact
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>

        <Form.Item
          name="heurearriveechantier"
          label={
            <Space align="center">
              <FaClock />
              Heure d'arrivée au chantier
            </Space>
          }>
          <Input />
        </Form.Item>
        <Form.Item
          name="commentaires"
          label={
            <Space align="center">
              <FaComments />
              Commentaires
            </Space>
          }>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="documents"
          label={
            <Space align="center">
              <FaBook />
              Documents
            </Space>
          }>
          <UploadFile
            data={data}
            informations={informations}
            setInformations={setInformations}
            selectedFichiers={selectedFichiers}
          />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Informations;
