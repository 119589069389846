import {
  Card,
  Form,
  Input,
  Space,
  Button,
  InputNumber,
  Collapse,
  Row,
  Col,
  Select,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { FaMagic } from "react-icons/fa";
import quantiteData from "./quantiteData.json";
import { useState } from "react";
const { Panel } = Collapse;

const MagicFill = ({ onClick, disabled }) => {
  return (
    <Button
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
      }}
      type="primary">
      <FaMagic />
    </Button>
  );
};

const MarquageMoyenneDuree = ({ form, disabled }) => {
  const [camionBille, setCamionBille] = useState();
  const camionsList = Object.keys(quantiteData.md)
    .sort((a, b) => a.localeCompare(b))
    .map((camion) => ({
      value: camion,
      label: camion,
    }));
  return (
    <Card
      title="Marquage Moyenne Durée"
      headStyle={{
        backgroundColor: "#001529",
        height: 50,
        display: "flex",
        alignItems: "center",
        color: "white",
      }}
      bodyStyle={{
        paddingTop: 15,
        paddingBottom: 15,
        display: "flex",
        flexDirection: "column",
      }}>
      <Collapse
        defaultActiveKey={["1"]}
        onChange={(e) => {
          if (disabled) return;
          if (!e.includes("1")) {
            form.resetFields([
              "md_reservoir_jaune_lot",
              "md_reservoir_jaune_total",
              "md_reservoir_blanc_lot",
              "md_reservoir_blanc_total",
              "md_reservoir_durcisseur_lot",
              "md_reservoir_durcisseur_total",
              "md_reservoir_bille",
            ]);
          }
        }}>
        <Panel header="Réservoir" key="1">
          <div style={{ display: "flex", marginBottom: 10, gap: 15 }}>
            <Card
              title="Jaune"
              style={{ flex: 1 }}
              headStyle={{
                backgroundColor: "#fcba03",
                color: "white",
                padding: "16px 24px",
              }}>
              <Form.Item label="# Lot" name="md_reservoir_jaune_lot">
                <Input />
              </Form.Item>
              <Form.Item
                label="Total (L)"
                name="md_reservoir_jaune_total"
                rules={[{ required: true, message: "À remplir" }]}>
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Card>
            <Card
              title="Blanc"
              style={{ flex: 1 }}
              headStyle={{
                backgroundColor: "#ffffff",
                color: "black",
                padding: "16px 24px",
              }}>
              <Form.Item label="# Lot" name="md_reservoir_blanc_lot">
                <Input />
              </Form.Item>
              <Form.Item
                label="Total (L)"
                name="md_reservoir_blanc_total"
                rules={[{ required: true, message: "À remplir" }]}>
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Card>
            <Card
              title="Durcisseur"
              style={{ flex: 1 }}
              headStyle={{
                backgroundColor: "#000000",
                color: "white",
                padding: "16px 24px",
              }}>
              <Form.Item label="# Lot" name="md_reservoir_durcisseur_lot">
                <Input />
              </Form.Item>
              <Form.Item
                label="Total (L)"
                name="md_reservoir_durcisseur_total"
                rules={[{ required: true, message: "À remplir" }]}>
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Card>
          </div>
          <Form.List name="md_reservoir_couleurspeciale">
            {(fields, { add, remove }, { errors }) => (
              <Card
                title="Couleur Spéciale"
                extra={
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => add()}
                  />
                }
                headStyle={{
                  backgroundColor: "#54b86f",
                  color: "white",
                }}
                style={{ flex: 1 }}
                bodyStyle={{
                  padding: 1,
                }}>
                <Row>
                  {fields.map((field, index) => (
                    <Col span={8} gutter={[15, 15]} key={field.key}>
                      <Card
                        title={`Couleur #${index + 1}`}
                        extra={
                          <Button
                            icon={<MinusOutlined />}
                            type="danger"
                            onClick={() => remove(field.name)}
                          />
                        }
                        bordered={false}
                        key={field.key}>
                        <Form.Item
                          label="Couleur"
                          name={[field.name, "couleur"]}
                          rules={[{ required: true, message: "À remplir" }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item label="# Lot" name={[field.name, "lot"]}>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Total (L)"
                          name={[field.name, "total"]}
                          rules={[{ required: true, message: "À remplir" }]}>
                          <Input />
                        </Form.Item>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card>
            )}
          </Form.List>
          <Form.List name="md_reservoir_bille">
            {(fields, { add, remove }, { errors }) => (
              <Card
                title="Bille"
                extra={
                  <Space>
                    <Select
                      placeholder="Choisir un camion"
                      allowClear
                      value={camionBille}
                      options={camionsList}
                      onChange={(e) => {
                        setCamionBille(e);
                      }}
                      style={{ width: 175 }}
                    />
                    <MagicFill
                      disabled={camionBille ? false : true}
                      onClick={() => {
                        const values = form.getFieldsValue();
                        let newBille = [];
                        const multiplier = quantiteData.md[camionBille].bille;
                        console.log({ multiplier });
                        values.md_reservoir_bille?.forEach((res) => {
                          const total_gauche =
                            res.arrivee_gauche >= res.depart_gauche
                              ? (res.arrivee_gauche - res.depart_gauche) *
                                multiplier
                              : "erreur";
                          const total_droite =
                            res.arrivee_droite >= res.depart_droite
                              ? (res.arrivee_droite - res.depart_droite) *
                                multiplier
                              : "erreur";
                          newBille.push({
                            ...res,
                            total_gauche,
                            total_droite,
                          });
                        });
                        form.setFieldValue("md_reservoir_bille", newBille);
                      }}
                    />
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  </Space>
                }
                style={{
                  flex: 1,
                  marginTop: 10,
                }}
                headStyle={{
                  backgroundColor: "#94928d",
                  color: "white",
                }}
                bodyStyle={
                  {
                    // padding: 24,
                  }
                }>
                {fields.map((field, index) => (
                  <Card
                    title={`Réservoir #${index + 1}`}
                    extra={
                      index >= 1 && (
                        <Button
                          icon={<MinusOutlined />}
                          type="danger"
                          onClick={() => remove(field.name)}
                        />
                      )
                    }
                    bordered={false}
                    key={field.key}>
                    <div style={{ display: "flex", gap: 50, marginBottom: 10 }}>
                      <Form.Item
                        style={{ flex: 1, margin: 0 }}
                        label="Niveau départ">
                        <Space size={20}>
                          <Space>
                            <span>G:</span>
                            <Form.Item
                              name={[field.name, "depart_gauche"]}
                              noStyle>
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Space>
                          <Space>
                            <span>D:</span>
                            <Form.Item
                              name={[field.name, "depart_droite"]}
                              noStyle>
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Space>
                        </Space>
                      </Form.Item>
                      <Form.Item
                        style={{ flex: 1, margin: 0 }}
                        label="Niveau arrivée">
                        <Space size={20}>
                          <Space>
                            <span>G:</span>
                          </Space>
                          <Form.Item
                            name={[field.name, "arrivee_gauche"]}
                            noStyle>
                            <InputNumber style={{ width: "100%" }} />
                          </Form.Item>
                          <Space>
                            <span>D:</span>
                            <Form.Item
                              name={[field.name, "arrivee_droite"]}
                              noStyle>
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Space>
                        </Space>
                      </Form.Item>
                    </div>
                    <Form.Item
                      label="Total Gauche (kg)"
                      name={[field.name, "total_gauche"]}
                      rules={[{ required: true, message: "À remplir" }]}>
                      <InputNumber style={{ width: "100%" }} min={0} />
                    </Form.Item>
                    <Form.Item
                      label="Total Droite (kg)"
                      name={[field.name, "total_droite"]}
                      rules={[{ required: true, message: "À remplir" }]}>
                      <InputNumber style={{ width: "100%" }} min={0} />
                    </Form.Item>
                  </Card>
                ))}
              </Card>
            )}
          </Form.List>
        </Panel>
      </Collapse>
    </Card>
  );
};

export default MarquageMoyenneDuree;
