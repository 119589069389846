import { Space, Form, Input, Button, Card, List, Row, Col, Spin } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useEffect, useContext } from "react";
import { Context } from "../utils/Context";

const I18n = {
  premarquage: { name: "Prémarquage", couleur: false },
  marquagecourteduree: { name: "Marquage Courte Durée", couleur: true },
  effacement: { name: "Effacement", couleur: false },
  marquagemoyenneduree: { name: "Marquage Moyenne Durée", couleur: true },
  marquagelongueduree: { name: "Marquage Longue Durée", couleur: true },
  mma: { name: "MMA", couleur: true },
  bandesrugueuses: { name: "Bandes Rugueuses", couleur: false },
  diversetmobilisation: { name: "Divers & Mobilisations", couleur: false },
};

const Quantites = ({
  loading,
  feuilleDeRoute,
  feuilleDeRouteQuantites,
  saveFeuilleDeRouteData,
  quantitesSaved,
}) => {
  const context = useContext(Context);
  const onFinish = (values) => {
    console.log("Success:", values);
    saveFeuilleDeRouteData("quantites", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  useEffect(() => {
    let feuilleDeRouteData = {};
    Object.values(feuilleDeRouteQuantites).map((categorie) =>
      categorie.map((article) => (feuilleDeRouteData[article._id] = article))
    );
    form.setFieldsValue(feuilleDeRouteData);
  }, [feuilleDeRouteQuantites]);
  const isDisabled = () => {
    if (
      context.loggedUser?.groupe === "Master" ||
      context.loggedUser?.groupe === "Administrateur" ||
      context.loggedUser?.groupe === "Comptabilite"
    )
      return false;
    if (
      feuilleDeRoute.status === "todo" ||
      feuilleDeRoute.status === "complete"
    ) {
      return false;
    }
    return true;
  };
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      form={form}
      disabled={isDisabled()}
      scrollToFirstError={{ behavior: "smooth" }}
      onFieldsChange={() => {
        saveFeuilleDeRouteData("quantites", null);
      }}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      {loading && (
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 20,
          }}
        />
      )}
      <Space direction="vertical" style={{ width: "100%" }}>
        {Object.keys(feuilleDeRouteQuantites).map((categorie) => (
          <Card
            title={I18n[categorie].name}
            key={I18n[categorie].name}
            headStyle={{
              backgroundColor: "#001529",
              width: "100%",
              height: 50,
              padding: "0px 24px",
              display: "flex",
              alignItems: "center",
              color: "white",
            }}
            bodyStyle={{
              paddingTop: 15,
              paddingBottom: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            <List
              style={{ width: "100%" }}
              header={
                <Row style={{ width: "100%", fontWeight: "bold" }} gutter={15}>
                  <Col span={10}>Description</Col>
                  <Col span={2} style={{ textAlign: "center" }}>
                    Unité
                  </Col>
                  {!I18n[categorie].couleur ? (
                    <Col span={12} style={{ textAlign: "center" }}>
                      Quantité
                    </Col>
                  ) : (
                    <>
                      <Col
                        span={4}
                        style={{
                          textAlign: "center",
                        }}>
                        Jaune
                      </Col>
                      <Col span={4} style={{ textAlign: "center" }}>
                        Blanc
                      </Col>
                      <Col span={4} style={{ textAlign: "center" }}>
                        Skip
                      </Col>
                    </>
                  )}
                </Row>
              }
              bordered
              dataSource={feuilleDeRouteQuantites[categorie]}
              renderItem={(article, articleIndex) => {
                return (
                  <List.Item>
                    <Row style={{ width: "100%" }} gutter={15}>
                      <Col
                        span={10}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}>
                        {article.description}
                      </Col>
                      <Col
                        span={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        {article.unite || ""}
                      </Col>
                      {!I18n[categorie].couleur ? (
                        <Col span={12} style={{ textAlign: "center" }}>
                          <Space
                            direction="vertical"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}>
                            <span>Est.: {article.quantiteest || 0}</span>
                            <Form.Item
                              rules={[
                                {
                                  required: article.quantiteest ? true : false,
                                  message: "À remplir",
                                },
                              ]}
                              name={[article._id, "quantitereel"]}>
                              <Input style={{ textAlign: "center" }} />
                            </Form.Item>
                          </Space>
                        </Col>
                      ) : (
                        <>
                          <Col span={4} style={{ textAlign: "center" }}>
                            <Space direction="vertical">
                              <span>Est.: {article.jauneest || 0}</span>
                              <Form.Item
                                style={{ marginBottom: 0 }}
                                rules={[
                                  {
                                    required: article.jauneest ? true : false,
                                    message: "À remplir",
                                  },
                                ]}
                                name={[article._id, "jaunereel"]}>
                                <Input style={{ textAlign: "center" }} />
                              </Form.Item>
                            </Space>
                          </Col>
                          <Col span={4} style={{ textAlign: "center" }}>
                            <Space direction="vertical">
                              <span>Est.: {article.blancest || 0}</span>
                              <Form.Item
                                rules={[
                                  {
                                    required: article.blancest ? true : false,
                                    message: "À remplir",
                                  },
                                ]}
                                name={[article._id, "blancreel"]}>
                                <Input style={{ textAlign: "center" }} />
                              </Form.Item>
                            </Space>
                          </Col>
                          <Col span={4} style={{ textAlign: "center" }}>
                            <Space direction="vertical">
                              <span>Est.: {article.skipest || 0}</span>
                              <Form.Item
                                rules={[
                                  {
                                    required: article.skipest ? true : false,
                                    message: "À remplir",
                                  },
                                ]}
                                name={[article._id, "skipreel"]}>
                                <Input style={{ textAlign: "center" }} />
                              </Form.Item>
                            </Space>
                          </Col>
                        </>
                      )}
                    </Row>
                  </List.Item>
                );
              }}
            />
          </Card>
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 10,
          }}>
          <Form.Item noStyle style={{ textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isDisabled()}
              icon={quantitesSaved && <CheckOutlined />}
              style={{
                backgroundColor: quantitesSaved && "green",
                borderColor: quantitesSaved && "green",
              }}>
              {quantitesSaved ? "Enregistré" : "Enregistrer"}
            </Button>
          </Form.Item>
        </div>
      </Space>
    </Form>
  );
};

export default Quantites;
